.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.w-form-formradioinput--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
}

.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox::after {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

body {
  background-color: #fff;
  font-family: Lato, sans-serif;
  color: #002e40;
  font-size: 1rem;
  line-height: 1.75;
  letter-spacing: -0.011em;
}

h1 {
  margin-top: 0px;
  margin-bottom: 1.5rem;
  font-size: 2.75rem;
  line-height: 1.25;
  font-weight: 600;
  letter-spacing: -0.022em;
}

h2 {
  margin-top: 1.5rem;
  margin-bottom: 1.25rem;
  font-size: 2.25rem;
  line-height: 1.25;
  font-weight: 600;
  letter-spacing: -0.021px;
}

h3 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1.75rem;
  line-height: 1.25;
  font-weight: 600;
  letter-spacing: -0.019px;
}

h4 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  line-height: 1.25;
  font-weight: 600;
  letter-spacing: -0.017em;
}

h5 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: 600;
  letter-spacing: -0.014em;
}

h6 {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;
  letter-spacing: -0.011em;
}

p {
  margin-bottom: 1.5rem;
  color: #4d6d79;
}

a {
  color: #00a5e1;
  text-decoration: underline;
}

ul {
  margin-bottom: 0rem;
  padding-left: 2rem;
  color: #4d6d79;
}

ol {
  margin-bottom: 1rem;
  padding-left: 2rem;
  color: #4d6d79;
}

li {
  margin-bottom: 0.25rem;
}

img {
  display: inline-block;
  max-width: 100%;
}

strong {
  color: #1a4353;
  font-weight: 600;
}

blockquote {
  margin-bottom: 1rem;
  padding: 0.75rem 1.5rem;
  border-left: 1px solid #8097a0;
  color: #4d6d79;
  font-size: 1rem;
  line-height: 1.75;
  font-style: italic;
}

.container {
  max-width: 1248px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;
}

.container.container-navbar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading {
  color: #002e40;
}

.heading.xl {
  margin-top: 0px;
  margin-bottom: 1.5rem;
  font-size: 2.75rem;
  line-height: 1.25;
  font-weight: 600;
  letter-spacing: -0.022em;
}

.heading.l {
  margin-top: 0px;
  margin-bottom: 1.25rem;
  font-size: 2.25rem;
  line-height: 1.25;
  font-weight: 600;
  letter-spacing: -0.021em;
}

.heading.l.s-m-b-0 {
  margin-bottom: 0rem;
}

.heading.m {
  margin-top: 0px;
  margin-bottom: 1rem;
  font-size: 1.75rem;
  line-height: 1.25;
  font-weight: 600;
  letter-spacing: -0.019em;
}

.heading.s {
  margin-top: 0px;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  line-height: 1.25;
  font-weight: 600;
  letter-spacing: -0.017em;
}

.heading.xs {
  margin-top: 0px;
  margin-bottom: 0.5rem;
  font-family: Inter, sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: 600;
  letter-spacing: -0.014em;
}

.heading.xxs {
  margin-top: 0px;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;
  letter-spacing: -0.011em;
}

.body-text {
  margin-top: 0px;
  color: #4d6d79;
}

.body-text.m {
  letter-spacing: -0.011em;
}

.body-text.m.bold {
  font-weight: 600;
}

.body-text.m.inline {
  display: inline;
}

.body-text.m.s-m-0 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.body-text.l {
  font-size: 1.125rem;
  line-height: 1.75;
  letter-spacing: -0.014em;
}

.body-text.s {
  margin-bottom: 1rem;
  font-size: 0.875rem;
  line-height: 1.4285;
  letter-spacing: 0em;
}

.body-text.s.s-m-b-0 {
  margin-bottom: 0rem;
}

.section-title {
  width: 50%;
  margin-bottom: 48px;
}

.section-title.centered {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.section-title._2-columns {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-right: -12px;
  margin-left: -12px;
}

.wrapper-m {
  padding-top: 64px;
  padding-bottom: 64px;
}

.l-g-3-col {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.feature-block.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.feature-block.centered {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.l-g-4-col {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.l-g-4-col.features {
  grid-row-gap: 48px;
}

.link-with-icon {
  display: inline-block;
  text-decoration: none;
}

.link-with-icon:hover {
  color: #0095cb;
}

.link-with-icon:active {
  color: #0084b4;
}

.link-with-icon:focus {
  box-shadow: 0 0 0 2px #e6eaec, 0 0 0 5px #00a5e1;
  color: #0095cb;
}

.link-with-icon.icon-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  color: #00a5e1;
}

.link-icon-text {
  display: inline-block;
  font-weight: 500;
}

.button {
  position: relative;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #005371;
  -webkit-transition: background-color 250ms ease;
  transition: background-color 250ms ease;
  color: #e6eaec;
  text-align: center;
  text-decoration: none;
}

.button:hover {
  background-color: #33b7e7;
}

.button:active {
  background-color: #0095cb;
}

.button:focus {
  box-shadow: 0 0 0 2px #e6eaec, 0 0 0 5px #00a5e1;
}

.button.secondary {
  background-color: #f0f5ff;
  box-shadow: inset 0 0 0 1px #adc6ff;
  -webkit-transition: box-shadow 250ms ease, color 250ms ease, background-color 250ms ease;
  transition: box-shadow 250ms ease, color 250ms ease, background-color 250ms ease;
  color: #2f54eb;
}

.button.secondary:hover {
  box-shadow: inset 0 0 0 1px #2f54eb;
  color: #1d39c4;
}

.button.outline {
  background-color: #e6eaec;
  color: #002e40;
}

.button.outline:hover {
  background-color: #b3c0c6;
}

.button.medium {
  padding: 8px 12px;
  border-radius: 18px;
}

.button.medium:hover {
  background-color: #0095cb;
}

.button.medium:active {
  background-color: #0084b4;
}

.button.medium:focus {
  background-color: #0095cb;
}

.button.medium.icon-only {
  padding: 12px;
}

.button.medium.secondary {
  background-color: #e6eaec;
  box-shadow: none;
  color: #00a5e1;
}

.button.medium.secondary:hover {
  background-color: #e6f6fc;
}

.button.medium.secondary:active {
  background-color: #ccedf9;
}

.button.medium.secondary:focus {
  box-shadow: 0 0 0 2px #e6eaec, 0 0 0 5px #00a5e1;
}

.button.medium.outline {
  box-shadow: inset 0 0 0 1px #99abb3;
}

.button.medium.outline:hover {
  background-color: #ccd5d9;
}

.button.medium.outline:active {
  background-color: #b3c0c6;
}

.button.medium.outline:focus {
  background-color: #ccd5d9;
  box-shadow: 0 0 0 2px #e6eaec, 0 0 0 5px #00a5e1, inset 0 0 0 1px #99abb3;
}

.button.medium.outline.multiple {
  width: 100%;
  height: 44px;
  margin-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.08), inset 0 0 0 1px #99abb3;
}

.button.medium.form-button {
  width: 100%;
  margin-bottom: 12px;
}

.button.medium.form-button.s-m-0 {
  margin-bottom: 0px;
}

.button.medium.ghost {
  background-color: transparent;
  box-shadow: inset 0 0 0 1px #fff;
}

.button.medium.ghost:hover {
  box-shadow: inset 0 0 0 1px #e6eaec;
}

.button.medium.ghost:active {
  background-color: hsla(0, 0%, 100%, 0.1);
  box-shadow: inset 0 0 0 1px #e6eaec;
  color: #e6eaec;
}

.button.medium.ghost:focus {
  box-shadow: inset 0 0 0 1px #e6eaec;
}

.button.small {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.button.small:hover {
  background-color: #0095cb;
}

.button.small:active {
  background-color: #0084b4;
}

.button.small:focus {
  background-color: #0095cb;
}

.button.small.icon-only {
  padding: 6px 8px;
}

.button.small.secondary {
  background-color: #e6eaec;
  box-shadow: none;
  color: #00a5e1;
}

.button.small.secondary:hover {
  background-color: #e6f6fc;
}

.button.small.secondary:active {
  background-color: #ccedf9;
}

.button.small.secondary:focus {
  color: #33b7e7;
}

.button.small.outline {
  box-shadow: 0 0 0 1px #99abb3;
}

.button.small.outline:hover {
  background-color: #ccd5d9;
}

.button.small.outline:active {
  background-color: #b3c0c6;
}

.button.small.outline:focus {
  background-color: #ccd5d9;
}

.button.small.ghost {
  background-color: transparent;
  box-shadow: 0 0 0 1px #fff;
}

.button.small.ghost:hover {
  box-shadow: 0 0 0 1px #e6eaec;
}

.button.small.ghost:active {
  background-color: hsla(0, 0%, 100%, 0.1);
  box-shadow: 0 0 0 1px #e6eaec;
  color: #e6eaec;
}

.button.small.ghost:focus {
  box-shadow: 0 0 0 1px #e6eaec;
}

.button.small.cookies {
  margin-left: 16px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.button-text {
  display: inline-block;
  font-weight: 500;
}

.dropdown-menu-navbar {
  z-index: 8;
}

.dropdown-menu-navbar.w--open {
  overflow: hidden;
  width: 200px;
  margin-top: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #99abb3;
  border-radius: 6px;
  background-color: #e6eaec;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  color: #1a4353;
}

.dropdown-menu-navbar.dark.w--open {
  border-style: solid;
  border-width: 1px;
  border-color: #4d6d79;
  background-color: #1a4353;
}

.dropdown-menu-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 6px 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #e6eaec;
}

.dropdown-menu-item:hover {
  background-color: #e6f6fc;
}

.dropdown-menu-item.w--current {
  background-color: #2f54eb;
  color: #e6eaec;
}

.breadcrumbs-link {
  color: #4d6d79;
  font-weight: 500;
  text-decoration: none;
}

.breadcrumbs-link:hover {
  color: #00a5e1;
}

.breadcrumbs-link:active {
  color: #0095cb;
}

.breadcrumbs-link:focus {
  color: #00a5e1;
}

.breadcrumbs-link.w--current {
  color: #002e40;
}

.breadcrumbs-separator {
  display: inline-block;
  padding-right: 8px;
  padding-left: 8px;
  color: #66828c;
  line-height: 24px;
}

.l-g-2-col {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.l-g-2-col.features {
  grid-row-gap: 48px;
}

.inline-link {
  color: #00a5e1;
  font-weight: 500;
  text-decoration: none;
}

.inline-link:hover {
  color: #0095cb;
}

.inline-link:active {
  color: #0084b4;
}

.inline-link:focus {
  box-shadow: 0 0 0 2px #e6eaec, 0 0 0 5px #00a5e1;
  color: #0095cb;
}

.input {
  margin-bottom: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #99abb3;
  border-radius: 20px;
  background-color: #fff;
  font-size: 1rem;
  font-weight: 400;
}

.input:hover {
  border-color: #66c9ed;
}

.input:active {
  border-color: #33b7e7;
}

.input:focus {
  border-color: #00a5e1;
  background-color: #e6eaec;
}

.input::-webkit-input-placeholder {
  color: #4d6d79;
}

.input:-ms-input-placeholder {
  color: #4d6d79;
}

.input::-ms-input-placeholder {
  color: #4d6d79;
}

.input::placeholder {
  color: #4d6d79;
}

.input.medium {
  height: 44px;
  margin-bottom: 12px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
}

.input.medium.horizontal {
  margin-right: 8px;
  margin-bottom: 0px;
}

.input.medium.bg-dark {
  border-style: none;
  border-color: #4d6d79;
  background-color: #1a4353;
  color: #66828c;
}

.input.medium.bg-dark:hover {
  border-color: #4d6d79;
}

.input.medium.bg-dark:focus {
  border-color: #4d6d79;
  color: #e6eaec;
}

.input.medium.bg-dark::-webkit-input-placeholder {
  color: #4d6d79;
}

.input.medium.bg-dark:-ms-input-placeholder {
  color: #4d6d79;
}

.input.medium.bg-dark::-ms-input-placeholder {
  color: #4d6d79;
}

.input.medium.bg-dark::placeholder {
  color: #4d6d79;
}

.input.medium.icon-left {
  position: relative;
  padding-left: 36px;
}

.input.medium.icon-right {
  position: relative;
  padding-right: 36px;
  padding-left: 12px;
}

.input.textarea {
  min-height: 96px;
  margin-bottom: 12px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.03);
}

.form.form-horizontal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 8px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cta-wrapper {
  width: 50%;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.tagline {
  display: inline-block;
  margin-bottom: 1.25rem;
  padding-right: 8px;
  padding-left: 8px;
  border-radius: 6px;
  background-color: #e6f6fc;
  color: #00a5e1;
  font-size: 0.875rem;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: -0.006em;
}

.feature-text-wrapper {
  padding-top: 8px;
  padding-left: 24px;
}

.card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transition: color 250ms ease, border-color 250ms ease;
  transition: color 250ms ease, border-color 250ms ease;
  color: #002e40;
  text-decoration: none;
}

.card:hover {
  color: #00a5e1;
}

.card:focus {
  color: #00a5e1;
}

.card.card-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 6px;
  background-color: #e6eaec;
  box-shadow: 0 4px 8px 0 rgba(93, 99, 112, 0.15);
  -webkit-transition: box-shadow 250ms ease, color 250ms ease, border-color 250ms ease;
  transition: box-shadow 250ms ease, color 250ms ease, border-color 250ms ease;
}

.card.card-box:hover {
  border-color: #2f54eb;
  box-shadow: 0 6px 10px 0 rgba(93, 99, 112, 0.2);
  color: #00a5e1;
}

.card.card-box:active {
  box-shadow: 0 2px 6px 0 rgba(93, 99, 112, 0.2);
}

.card.card-horizontal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding: 24px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 6px;
  background-color: #e6eaec;
  box-shadow: 0 4px 8px 0 rgba(93, 99, 112, 0.1);
}

.card.card-horizontal:hover {
  box-shadow: 0 6px 10px 0 rgba(93, 99, 112, 0.2);
}

.card.card-horizontal:active {
  box-shadow: 0 2px 6px 0 rgba(93, 99, 112, 0.2);
}

.card.card-blog-big {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.card-image {
  height: 256px;
  margin-bottom: 24px;
  border-radius: 6px;
  -o-object-fit: cover;
  object-fit: cover;
}

.card-image.card-image-box {
  margin-bottom: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.card-text-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.card-text-wrapper.centered {
  text-align: center;
}

.card-horizontal-text-wrapper {
  margin-left: 16px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.button-separator {
  display: inline-block;
  width: 8px;
  height: 8px;
}

.side-nav-menu {
  position: -webkit-sticky;
  position: sticky;
  top: 32px;
}

.side-nav-menu-item {
  display: block;
  padding: 6px 16px;
  border-radius: 6px;
  background-color: transparent;
  color: #4d6d79;
  text-decoration: none;
}

.side-nav-menu-item:hover {
  background-color: #e6f6fc;
}

.side-nav-menu-item:active {
  color: #0095cb;
}

.side-nav-menu-item:focus {
  color: #33b7e7;
}

.side-nav-menu-item.w--current {
  background-color: #e6f6fc;
  color: #00a5e1;
}

.side-nav-menu-item.w--current:hover {
  background-color: #e6f6fc;
}

.side-nav-menu-item.small {
  padding: 6px 12px;
  font-size: 0.875rem;
  line-height: 1.5;
}

.tab {
  padding: 12px 16px;
  background-color: #e6eaec;
  box-shadow: inset 0 -1px 0 0 #8097a0;
  color: #4d6d79;
}

.tab:hover {
  color: #00a5e1;
}

.tab:active {
  background-color: #e6f6fc;
}

.tab:focus {
  background-color: #e6f6fc;
  color: #00a5e1;
}

.tab.w--current {
  padding: 12px 16px;
  background-color: #e6eaec;
  box-shadow: inset 0 -2px 0 0 #00a5e1;
  color: #00a5e1;
}

.tab.w--current:active {
  background-color: #e6f6fc;
}

.tab.w--current:focus {
  background-color: #e6f6fc;
}

.tag {
  display: inline-block;
  margin-right: 4px;
  padding: 0px 4px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #99abb3;
  border-radius: 6px;
  background-color: #ccd5d9;
  color: #1a4353;
  font-size: 0.875rem;
  line-height: 24px;
  font-weight: 400;
}

.tag.tag-secondary {
  border-color: #f5f6c4;
  background-color: #f8f9d8;
  color: #e0e54e;
}

.tag.tag-success {
  border-color: #cce7e0;
  background-color: #e6f3f0;
  color: #006a52;
}

.tag.tag-warning {
  border-color: #ffe58f;
  background-color: #fffbe6;
  color: #874d00;
}

.tag.tag-error {
  border-color: #f8d6cf;
  background-color: #fbeae7;
  color: #c52c0e;
}

.tag.tag-info {
  border-color: #cce5ef;
  background-color: #e6f2f7;
  color: #4da5c6;
}

.tag.tag-primary {
  border-color: #99dbf3;
  background-color: #e6f6fc;
  color: #00a5e1;
}

.tag.tag-closeable {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.input-title {
  margin-bottom: 0.25rem;
  font-weight: 600;
}

.form-footer-links {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 0.875rem;
  line-height: 1.5;
}

.footer {
  padding-top: 64px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-top: 1px solid #99abb3;
  background-color: #e6eaec;
  text-align: left;
}

.footer.dark {
  border-top-color: #1a4353;
  background-color: #002e40;
  color: #e6eaec;
}

.material-icons.icon-right {
  margin-left: 4px;
}

.material-icons.icon-right.icon-dropdown {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.material-icons.icon-right.icon-close {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 0.875rem;
  line-height: 0.875rem;
}

.material-icons.icon-left {
  margin-top: 2px;
  margin-right: 4px;
}

.material-icons.icon-left.icon-list-item {
  margin-top: 3px;
  margin-right: 4px;
  font-size: 18px;
}

.material-icons.icon-left.icon-list-item.error {
  color: #db310f;
}

.material-icons.icon-left.icon-list-item.warning {
  color: #faad14;
}

.material-icons.icon-left.icon-list-item.success {
  color: #008566;
}

.material-icons.icon-left.icon-list-item.info {
  color: #67b2ce;
}

.material-icons.icon-left.icon-collapse {
  margin-top: 0px;
  margin-right: 0px;
  color: #4d6d79;
}

.material-icons.icon-left.icon-alert-banner {
  margin-top: 2px;
}

.material-icons.icon-left.icon-alert-banner.c-t-secondary-60 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-right: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.material-icons.small {
  font-size: 16px;
}

.material-icons.small.icon-right {
  margin-top: 1px;
  margin-left: 4px;
}

.material-icons.small.icon-left {
  margin-top: 1px;
  margin-right: 4px;
}

.material-icons.icon-input-left {
  position: absolute;
  left: 12px;
  top: 50%;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  color: #4d6d79;
}

.material-icons.icon-input-right {
  position: absolute;
  left: auto;
  top: 50%;
  right: 12px;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  color: #4d6d79;
}

.material-icons.icon-close {
  cursor: pointer;
}

.material-icons.icon-close:hover {
  opacity: 0.5;
}

.material-icons.feature-icon-small {
  width: 1.5rem;
  height: 1.5rem;
  margin-bottom: 0.5rem;
  color: #00a5e1;
  font-size: 1.5rem;
}

.material-icons.feature-icon-small.slider-icon {
  color: #4d6d79;
}

.material-icons.feature-icon-small.slider-icon:hover {
  color: #4d6d79;
}

.material-icons.star {
  margin-right: 4px;
  color: #faad14;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.material-icons.dropdown-menu-item-icon {
  width: 1.4rem;
  height: 1.4rem;
  margin-top: 0px;
  margin-right: 0.5rem;
  margin-bottom: -2px;
  color: #4d6d79;
  font-size: 1.4rem;
}

.material-icons.caret-down {
  margin-left: 4px;
}

.material-icons.icon-input-right {
  position: absolute;
  left: auto;
  top: 50%;
  right: 12px;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  color: #4d6d79;
}

.material-icons.icon-back {
  margin-right: 4px;
}

.icon-list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.25rem;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.icon-list-item.vertical {
  margin-bottom: 0px;
  padding-right: 8px;
  padding-left: 8px;
}

.grid-table {
  overflow: hidden;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto auto auto auto;
  grid-template-rows: auto auto auto auto auto auto;
  border-style: solid;
  border-width: 1px;
  border-color: #99abb3;
  border-radius: 6px;
}

.grid-table.headings-table {
  margin-bottom: 48px;
  -ms-grid-columns: 0.4fr 0.3fr 0.3fr 1fr;
  grid-template-columns: 0.4fr 0.3fr 0.3fr 1fr;
  -ms-grid-rows: auto auto auto auto auto auto auto;
  grid-template-rows: auto auto auto auto auto auto auto;
}

.grid-table.body-text-table {
  margin-bottom: 48px;
  -ms-grid-columns: 0.4fr 0.3fr 0.3fr 1fr;
  grid-template-columns: 0.4fr 0.3fr 0.3fr 1fr;
  -ms-grid-rows: auto auto auto auto;
  grid-template-rows: auto auto auto auto;
}

.grid-table.alignment {
  -ms-grid-columns: 0.5fr 0.5fr 1.5fr;
  grid-template-columns: 0.5fr 0.5fr 1.5fr;
  -ms-grid-rows: auto auto auto auto auto;
  grid-template-rows: auto auto auto auto auto;
}

.table-cell {
  padding: 10px 12px;
  box-shadow: inset 0 -1px 0 0 #99abb3;
  color: #4d6d79;
}

.table-cell.last {
  box-shadow: none;
}

.table-header {
  padding: 10px 12px;
  background-color: #ccd5d9;
  box-shadow: inset 0 -1px 0 0 #99abb3;
  color: #002e40;
  font-size: 1rem;
  font-weight: 600;
}

.navbar {
  position: relative;
  background-color: #e6eaec;
}

.navbar.dark {
  position: relative;
  background-color: #002e40;
}

.navbar-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-link {
  padding: 8px 16px;
  border-radius: 6px;
  color: #4d6d79;
  text-decoration: none;
}

.nav-link:hover {
  color: #00a5e1;
}

.nav-link:active {
  background-color: #ccd5d9;
}

.nav-link.w--current {
  color: #00a5e1;
}

.nav-link.nav-link-dropdown {
  padding-right: 36px;
}

.nav-link.nav-link-dropdown.w--open {
  color: #2f54eb;
}

.nav-link.nav-link-dropdown.color-inverse.w--open {
  color: #e6eaec;
}

.nav-link.color-inverse {
  color: #66828c;
}

.nav-link.color-inverse:hover {
  color: #e6eaec;
}

.nav-link.color-inverse:active {
  background-color: #1a4353;
}

.nav-link.color-inverse:focus {
  color: #e6eaec;
}

.dropdown-icon {
  margin-right: 16px;
}

.footer-grid {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.brand-logo {
  margin-right: 24px;
}

.navbar-menu-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-logo {
  padding-bottom: 0px;
}

.vertical-menu-title {
  margin-bottom: 12px;
  font-weight: 600;
}

.vertical-menu-title.color-inverse {
  color: #e6eaec;
}

.vertical-menu-list-item {
  margin-bottom: 8px;
}

.footer-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 48px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.footer-social-media {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.footer-bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 32px;
  padding-top: 32px;
  padding-bottom: 32px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #4d6d79;
}

.footer-bottom.color-inverse {
  color: #4d6d79;
}

.vertical-menu-link {
  margin-bottom: 0px;
  color: #4d6d79;
  text-decoration: none;
}

.vertical-menu-link:hover {
  color: #00a5e1;
  text-decoration: none;
}

.vertical-menu-link.color-inverse {
  color: #4d6d79;
}

.vertical-menu-link.color-inverse:hover {
  color: #e6eaec;
}

.social-media-icon {
  display: inline-block;
  padding-right: 0px;
  padding-left: 0px;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.social-media-icon:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.sm-icon-link {
  margin-right: 12px;
}

.footer-grid-2 {
  -ms-grid-columns: 1.5fr 1fr 1fr 1fr;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.footer-logo-2 {
  position: static;
  display: block;
  margin-bottom: 16px;
  float: none;
}

.footer-grid-3 {
  grid-column-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr 1.75fr;
  grid-template-columns: 1fr 1fr 1fr 1.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  text-align: left;
}

.footer-top-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-bottom-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 32px;
  padding-top: 24px;
  padding-bottom: 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #4d6d79;
}

.footer-bottom-2.color-inverse {
  border-top-color: #1a4353;
  color: #4d6d79;
}

.footer-2 {
  padding-top: 32px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-top: 1px solid #99abb3;
  background-color: #e6eaec;
  text-align: left;
}

.footer-2.dark {
  border-top-color: #1a4353;
  background-color: #002e40;
}

.sm-icons-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 24px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.grid-cta {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 0.75fr 1fr;
  grid-template-columns: 0.75fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.cta-section-form-wrapper {
  width: 480px;
}

.cta-section-form-wrapper.center {
  margin-top: 32px;
  margin-right: auto;
  margin-left: auto;
}

.footer-menu-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-menu-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-menu-mobile {
  display: none;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.breadcrumbs {
  display: block;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #4d6d79;
}

.breadcrumbs.hero-breadcrumbs {
  margin-bottom: 64px;
}

.text-icon-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-icon-wrapper.centered {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.alert-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 16px;
  border-style: solid;
  border-width: 1px;
  border-color: #b3d9e7;
  border-radius: 6px;
  background-color: #e6f2f7;
  color: #1a4353;
}

.popup-title {
  margin-bottom: 8px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #002e40;
  font-weight: 600;
}

.popup-title.color-inverse {
  color: #e6eaec;
}

.toast {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 640px;
  padding: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: solid;
  border-width: 1px;
  border-color: #99abb3;
  border-radius: 6px;
  background-color: #e6eaec;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  color: #1a4353;
}

.toast.dark {
  border-color: #1a4353;
  background-color: #002e40;
  color: #e6eaec;
}

.toast-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.modal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  max-width: 480px;
  margin-right: auto;
  margin-left: auto;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: solid;
  border-width: 1px;
  border-color: #8097a0 #8097a0 #99abb3;
  border-radius: 6px;
  background-color: #e6eaec;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
  color: #1a4353;
}

.checkbox {
  width: 20px;
  height: 20px;
  margin-top: 0px;
  margin-right: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #8097a0;
  border-radius: 2px;
  background-color: #e6eaec;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  cursor: pointer;
}

.checkbox:hover {
  border-color: #33b7e7;
}

.checkbox.w--redirected-checked {
  border-color: #00a5e1;
  background-color: #00a5e1;
  background-image: url('../images/check.svg');
  background-size: 12px 12px;
}

.checkbox.w--redirected-focus {
  border-color: #33b7e7;
  box-shadow: 0 0 0 2px #e6eaec, 0 0 0 5px #00a5e1;
}

.checkbox-label {
  margin-bottom: 0px;
  color: #4d6d79;
  font-weight: 400;
}

.checkbox-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 4px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.radio-button-label {
  margin-bottom: 0px;
  color: #4d6d79;
  font-weight: 400;
}

.radio-button {
  width: 20px;
  height: 20px;
  margin-top: 0px;
  margin-right: 8px;
  border-color: #8097a0;
  background-color: #e6eaec;
  cursor: pointer;
}

.radio-button:hover {
  border-color: #33b7e7;
}

.radio-button.w--redirected-checked {
  width: 20px;
  height: 20px;
  border-width: 6px;
  border-color: #00a5e1;
  background-color: #e6eaec;
}

.radio-button.w--redirected-focus {
  border-color: #33b7e7;
  box-shadow: 0 0 0 2px #e6eaec, 0 0 0 5px #00a5e1;
}

.radio-button-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 4px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.switch-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 4px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.switch {
  width: 36px;
  height: 20px;
  margin-top: 0px;
  margin-right: 8px;
  border-style: none;
  border-radius: 10px;
  background-color: #99abb3;
  background-image: url('../images/Switch-Handle.svg');
  background-position: 2px 50%;
  background-size: 16px;
  background-repeat: no-repeat;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  cursor: pointer;
}

.switch:hover {
  border-color: #33b7e7;
  background-color: #8097a0;
}

.switch.w--redirected-checked {
  border-width: 0px;
  border-color: #00a5e1;
  background-color: #00a5e1;
  background-image: url('../images/Switch-Handle.svg');
  background-position: 17px 50%;
  background-size: 16px;
}

.switch.w--redirected-focus {
  border-color: #33b7e7;
  box-shadow: 0 0 0 2px #e6eaec, 0 0 0 5px #00a5e1;
}

.switch-label {
  margin-bottom: 0px;
  color: #4d6d79;
  font-weight: 400;
}

.input-caption {
  color: #4d6d79;
  font-size: 0.875rem;
  line-height: 1.5;
  font-weight: 400;
}

.input-caption.color-inverse {
  color: #4d6d79;
}

.input-icon-wrapper {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.tooltip {
  position: absolute;
  left: 24%;
  top: auto;
  right: 0%;
  bottom: 36px;
  display: none;
  width: 240px;
  margin-bottom: 8px;
  padding: 8px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  background-color: #002e40;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
  color: #e6eaec;
  font-size: 0.875rem;
  line-height: 1.5;
  text-align: center;
}

.tooltip-arrow {
  position: absolute;
  left: 50%;
  top: auto;
  right: 50%;
  bottom: -6px;
  width: 12px;
  height: 12px;
  background-color: #002e40;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.text-block {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.tooltip-trigger-content {
  position: relative;
  bottom: -4px;
  display: inline-block;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.tooltip-trigger {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.modal-wrapper {
  position: fixed;
  z-index: 5000;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  border-bottom: 1px solid #99abb3;
}

.modal-header-title {
  margin-bottom: 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #002e40;
  font-weight: 600;
}

.modal-content {
  padding: 24px;
}

.collapse-item {
  padding-right: 16px;
  padding-left: 16px;
  background-color: #ccd5d9;
  cursor: pointer;
}

.collapse-item.last {
  border-bottom-style: none;
}

.collapse-text {
  display: none;
  padding-top: 0px;
  padding-bottom: 12px;
  padding-left: 32px;
  opacity: 0;
  color: #4d6d79;
}

.collapse-text.borderless {
  padding-left: 12px;
}

.collapse-trigger {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.collapse-title {
  display: inline-block;
  margin-left: 12px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: 500;
}

.collapse {
  overflow: hidden;
  border-radius: 6px;
}

.collapse.borderless {
  padding: 24px;
  border-style: none;
  background-color: #e6eaec;
}

.alert-error {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 16px;
  border-style: solid;
  border-width: 1px;
  border-color: #f1ad9f;
  border-radius: 6px;
  background-color: #fbeae7;
  color: #1a4353;
}

.alert-warning {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 16px;
  border-style: solid;
  border-width: 1px;
  border-color: #ffe58f;
  border-radius: 6px;
  background-color: #fffbe6;
  color: #1a4353;
}

.alert-success {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 16px;
  border-style: solid;
  border-width: 1px;
  border-color: #99cec2;
  border-radius: 6px;
  background-color: #e6f3f0;
  color: #1a4353;
}

.select {
  margin-bottom: 12px;
  border-style: solid;
  border-width: 1px;
  border-color: #8097a0;
  background-color: #ccd5d9;
}

.select:hover {
  border-color: #33b7e7;
}

.select:focus {
  border-color: #0095cb;
  background-color: #e6eaec;
}

.select.medium {
  height: 44px;
  border-color: #99abb3;
  border-radius: 6px;
  background-color: #e6eaec;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.component-wrapper {
  position: relative;
  margin-top: 64px;
  margin-bottom: 128px;
  padding: 24px;
  border-style: dashed;
  border-width: 1px;
  border-color: #66828c;
  border-radius: 0px;
}

.component-wrapper.dark {
  background-color: #002e40;
  color: #e6eaec;
}

.component-wrapper.last {
  margin-bottom: 0px;
}

.component-wrapper-label {
  position: absolute;
  left: -1px;
  top: -34px;
  right: auto;
  bottom: auto;
  display: block;
  padding: 6px 16px 6px 32px;
  border-radius: 6px 6px 0px 0px;
  background-color: #b3c0c6;
  background-image: url('../images/copy-icon.svg');
  background-position: 12px 50%;
  background-size: 11px;
  background-repeat: no-repeat;
  color: #1a4353;
  font-size: 0.875rem;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: -0.006em;
  cursor: pointer;
}

.component-wrapper-label:hover {
  opacity: 0.8;
}

.style-guide-page-header {
  border-bottom: 1px solid #99abb3;
  background-color: #ccd5d9;
  color: #002e40;
}

.input-with-label {
  margin-bottom: 1.5rem;
}

.grid-styleguide---buttons {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  text-align: center;
}

.dropdown-inline {
  padding: 0px;
  color: #4d6d79;
}

.dropdown-inline:hover {
  color: #00a5e1;
}

.dropdown-inline:active {
  color: #0095cb;
}

.dropdown-inline:focus {
  color: #0095cb;
}

.dropdown-inline.w--open {
  color: #00a5e1;
}

.style-guide-page-header-text {
  width: 65%;
  padding-top: 64px;
  padding-bottom: 64px;
}

.divider {
  width: 100%;
  height: 1px;
  margin-top: 80px;
  margin-bottom: 80px;
  background-color: #99abb3;
}

.button-fluid {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 8px 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 16px;
  background-color: #005371;
  -webkit-transition: background-color 250ms ease;
  transition: background-color 250ms ease;
  color: #e6eaec;
  text-align: center;
  text-decoration: none;
}

.button-fluid:hover {
  background-color: #33b7e7;
}

.button-fluid:active {
  background-color: #0095cb;
}

.button-fluid:focus {
  box-shadow: 0 0 0 2px #e6eaec, 0 0 0 5px #00a5e1;
}

.button-fluid.secondary {
  background-color: #f0f5ff;
  box-shadow: inset 0 0 0 1px #adc6ff;
  -webkit-transition: box-shadow 250ms ease, color 250ms ease, background-color 250ms ease;
  transition: box-shadow 250ms ease, color 250ms ease, background-color 250ms ease;
  color: #2f54eb;
}

.button-fluid.secondary:hover {
  box-shadow: inset 0 0 0 1px #2f54eb;
  color: #1d39c4;
}

.button-fluid.outline {
  background-color: #e6eaec;
  color: #002e40;
}

.button-fluid.outline:hover {
  background-color: #b3c0c6;
}

.button-fluid.medium {
  width: 100%;
  text-align: center;
}

.button-fluid.medium:hover {
  background-color: #0095cb;
}

.button-fluid.medium:active {
  background-color: #0084b4;
}

.button-fluid.medium:focus {
  background-color: #0095cb;
}

.button-fluid.medium.icon-only {
  padding: 12px 14px;
  font-size: 22px;
}

.button-fluid.medium.secondary {
  background-color: #e6eaec;
  box-shadow: none;
  color: #00a5e1;
}

.button-fluid.medium.secondary:hover {
  background-color: #e6f6fc;
}

.button-fluid.medium.secondary:active {
  background-color: #ccedf9;
}

.button-fluid.medium.secondary:focus {
  box-shadow: 0 0 0 2px #e6eaec, 0 0 0 5px #00a5e1;
}

.button-fluid.medium.outline {
  box-shadow: 0 0 0 1px #99abb3;
}

.button-fluid.medium.outline:hover {
  background-color: #ccd5d9;
}

.button-fluid.medium.outline:active {
  background-color: #b3c0c6;
}

.button-fluid.medium.outline:focus {
  background-color: #ccd5d9;
  box-shadow: 0 0 0 2px #e6eaec, 0 0 0 5px #00a5e1, 0 0 0 1px #99abb3;
}

.button-fluid.medium.ghost {
  background-color: transparent;
  box-shadow: inset 0 0 0 1px #fff;
}

.button-fluid.medium.ghost:hover {
  box-shadow: inset 0 0 0 1px #e6eaec;
}

.button-fluid.medium.ghost:active {
  background-color: hsla(0, 0%, 100%, 0.1);
  box-shadow: inset 0 0 0 1px #e6eaec;
}

.button-fluid.medium.ghost:focus {
  box-shadow: inset 0 0 0 1px #e6eaec;
}

.button-fluid.medium.enterprise {
  background-color: #e7eb75;
}

.button-fluid.medium.enterprise:hover {
  background-color: #e4e861;
}

.button-fluid.medium.enterprise:active {
  background-color: #e0e54e;
}

.button-fluid.medium.enterprise:focus {
  background-color: #e4e861;
}

.button-fluid.medium.dark {
  background-color: #002e40;
}

.button-fluid.medium.dark:hover {
  background-color: #1a4353;
}

.button-fluid.medium.dark:active {
  background-color: #4d6d79;
}

.button-fluid.medium.dark:focus {
  background-color: #1a4353;
}

.button-fluid.medium.dark-pro {
  background-color: #e6eaec;
  color: #002e40;
}

.button-fluid.medium.dark-pro:hover {
  background-color: #b3c0c6;
}

.button-fluid.medium.dark-pro:active {
  background-color: #99abb3;
}

.button-fluid.medium.dark-pro:focus {
  background-color: #b3c0c6;
  box-shadow: 0 0 0 2px #1a4353, 0 0 0 5px #e6eaec;
}

.button-fluid.small {
  width: 100%;
  padding: 4px 8px;
  font-size: 0.875rem;
  line-height: 1.5rem;
  text-align: center;
}

.button-fluid.small:hover {
  background-color: #0095cb;
}

.button-fluid.small:active {
  background-color: #0084b4;
}

.button-fluid.small:focus {
  background-color: #0095cb;
}

.button-fluid.small.icon-only {
  padding: 6px 8px;
}

.button-fluid.small.secondary {
  background-color: #e6eaec;
  box-shadow: none;
  color: #00a5e1;
}

.button-fluid.small.secondary:hover {
  background-color: #e6f6fc;
}

.button-fluid.small.secondary:active {
  background-color: #ccedf9;
}

.button-fluid.small.secondary:focus {
  background-color: #e6f6fc;
  box-shadow: 0 0 0 2px #e6eaec, 0 0 0 5px #00a5e1;
}

.button-fluid.small.outline {
  box-shadow: 0 0 0 1px #99abb3;
}

.button-fluid.small.outline:hover {
  background-color: #ccd5d9;
}

.button-fluid.small.outline:active {
  background-color: #b3c0c6;
}

.button-fluid.small.outline:focus {
  background-color: #ccd5d9;
  box-shadow: 0 0 0 2px #e6eaec, 0 0 0 5px #00a5e1, 0 0 0 1px #99abb3;
}

.button-fluid.small.ghost {
  background-color: transparent;
  box-shadow: inset 0 0 0 1px #fff;
}

.button-fluid.small.ghost:hover {
  box-shadow: inset 0 0 0 1px #e6eaec;
}

.button-fluid.small.ghost:active {
  background-color: hsla(0, 0%, 100%, 0.1);
  box-shadow: inset 0 0 0 1px #e6eaec;
  color: #e6eaec;
}

.button-fluid.small.ghost:focus {
  box-shadow: inset 0 0 0 1px #e6eaec;
}

.navbar-vertical {
  position: static;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  display: block;
  width: 280px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #e6eaec;
  box-shadow: inset -1px 0 0 0 #8097a0;
  color: #002e40;
  text-align: left;
}

.navbar-vertical.docsui {
  box-shadow: inset -1px 0 0 0 #99abb3;
}

.navbar-vertical-link {
  position: relative;
  display: block;
  padding: 8px 36px;
  color: #4d6d79;
  text-decoration: none;
}

.navbar-vertical-link:hover {
  background-color: #e6f6fc;
  box-shadow: inset -1px 0 0 0 #8097a0;
  color: #00a5e1;
}

.navbar-vertical-link:active {
  background-color: #ccedf9;
}

.navbar-vertical-link:focus {
  background-color: #e6f6fc;
  color: #00a5e1;
}

.navbar-vertical-link.w--current {
  background-color: #e6f6fc;
  box-shadow: inset -2px 0 0 0 #00a5e1;
  color: #00a5e1;
}

.navbar-vetrical-menu-title {
  margin-top: 24px;
  margin-bottom: 12px;
  padding-right: 24px;
  padding-left: 24px;
  color: #002e40;
  font-weight: 600;
}

.navbar-vetrical-menu-title-2 {
  margin-top: 12px;
  margin-bottom: 8px;
  padding-right: 24px;
  padding-left: 24px;
  color: #1a4353;
  font-size: 0.875rem;
  font-weight: 500;
}

.navbar-vertical-brand {
  width: 100%;
  margin-bottom: 16px;
  padding: 24px;
}

.style-guide {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.style-guide-page-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.navbar-vertical---mobile {
  position: relative;
  display: none;
  background-color: #e6eaec;
}

.navbar-vertical-overlay {
  display: block;
  margin-bottom: 128px;
}

.navbar-vertical-bg {
  display: none;
}

.nav-tabs-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1232px;
  margin-right: auto;
  margin-left: auto;
  padding: 0px 8px;
}

.style-guide-page-section-wrapper.narrow {
  max-width: 480px;
}

.nav-tab {
  padding: 12px 16px;
  color: #4d6d79;
}

.nav-tab:hover {
  color: #00a5e1;
}

.nav-tab:active {
  background-color: #e6f6fc;
  color: #0095cb;
}

.nav-tab:focus {
  background-color: #e6f6fc;
  color: #00a5e1;
}

.nav-tab.w--current {
  box-shadow: inset 0 -3px 0 0 #00a5e1;
  color: #00a5e1;
}

.nav-tabs {
  background-color: transparent;
  box-shadow: inset 0 -1px 0 0 #99abb3;
}

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;
}

.alert-content {
  padding-left: 4px;
}

.s-p-8 {
  padding: 8px;
}

.s-p-y-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.s-p-x-8 {
  padding-right: 8px;
  padding-left: 8px;
}

.s-p-t-8 {
  padding-top: 8px;
}

.s-p-r-8 {
  padding-right: 8px;
}

.s-p-b-8 {
  padding-bottom: 8px;
}

.s-p-l-8 {
  padding-left: 8px;
}

.s-p-12 {
  padding: 12px;
}

.s-p-y-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.s-p-x-12 {
  padding-right: 12px;
  padding-left: 12px;
}

.s-p-t-12 {
  padding-top: 12px;
}

.s-p-r-12 {
  padding-right: 12px;
}

.s-p-b-12 {
  padding-bottom: 12px;
}

.s-p-l-12 {
  padding-left: 12px;
}

.s-p-16 {
  padding: 16px;
}

.s-p-y-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.s-p-x-16 {
  padding-right: 12px;
  padding-left: 12px;
}

.s-p-t-16 {
  padding-top: 16px;
}

.s-p-r-16 {
  padding-right: 16px;
}

.s-p-b-16 {
  padding-bottom: 16px;
}

.s-p-l-16 {
  padding-left: 16px;
}

.s-p-24 {
  padding: 24px;
}

.s-p-y-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.s-p-x-24 {
  padding-right: 24px;
  padding-left: 24px;
}

.s-p-t-24 {
  padding-top: 24px;
}

.s-p-r-24 {
  padding-right: 24px;
}

.s-p-b-24 {
  padding-bottom: 24px;
}

.s-p-l-24 {
  padding-left: 24px;
}

.s-p-32 {
  padding: 32px;
}

.s-p-y-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.s-p-x-32 {
  padding-right: 32px;
  padding-left: 32px;
}

.s-p-t-32 {
  padding-top: 32px;
}

.s-p-r-32 {
  padding-right: 32px;
}

.s-p-b-32 {
  padding-bottom: 32px;
}

.s-p-l-32 {
  padding-left: 32px;
}

.s-p-48 {
  padding: 48px;
}

.s-p-y-48 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.s-p-x-48 {
  padding-right: 48px;
  padding-left: 48px;
}

.s-p-t-48 {
  padding-top: 48px;
}

.s-p-r-48 {
  padding-right: 48px;
}

.s-p-b-48 {
  padding-bottom: 48px;
}

.s-p-l-48 {
  padding-left: 48px;
}

.s-p-64 {
  padding: 64px;
}

.s-p-y-64 {
  padding-top: 64px;
  padding-bottom: 64px;
}

.s-p-x-64 {
  padding-right: 64px;
  padding-left: 64px;
}

.s-p-t-64 {
  padding-top: 64px;
}

.s-p-r-64 {
  padding-right: 64px;
}

.s-p-b-64 {
  padding-bottom: 64px;
}

.s-p-l-64 {
  padding-left: 64px;
}

.s-p-128 {
  padding: 128px;
}

.s-p-y-128 {
  padding-top: 128px;
  padding-bottom: 128px;
}

.s-p-t-128 {
  padding-top: 128px;
}

.s-p-x-128 {
  padding-right: 128px;
  padding-left: 128px;
}

.s-p-r-128 {
  padding-right: 128px;
}

.s-p-b-128 {
  padding-bottom: 128px;
}

.s-p-l-128 {
  padding-left: 128px;
}

.s-p-0 {
  padding: 0px;
}

.s-p-y-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.s-p-x-0 {
  padding-right: 0px;
  padding-left: 0px;
}

.s-p-t-0 {
  padding-top: 0px;
}

.s-p-r-0 {
  padding-right: 0px;
}

.s-p-b-0 {
  padding-bottom: 0px;
}

.s-p-l-0 {
  padding-left: 0px;
}

.s-p-4 {
  padding: 4px;
}

.s-p-y-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.s-p-x-4 {
  padding-right: 4px;
  padding-left: 4px;
}

.s-p-t-4 {
  padding-top: 4px;
}

.s-p-r-4 {
  padding-right: 4px;
}

.s-p-b-4 {
  padding-bottom: 4px;
}

.s-p-l-4 {
  padding-left: 4px;
}

.s-m-0 {
  margin: 0px;
}

.s-m-y-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.s-m-x-0 {
  margin-right: 0px;
  margin-left: 0px;
}

.s-m-t-0 {
  margin-top: 0px;
}

.s-m-r-0 {
  margin-right: 0px;
}

.s-m-b-0 {
  margin-bottom: 0px;
}

.s-m-l-0 {
  margin-left: 0px;
}

.s-m-4 {
  margin: 4px;
}

.s-m-y-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.s-m-x-4 {
  margin-right: 4px;
  margin-left: 4px;
}

.s-m-t-4 {
  margin-top: 4px;
}

.s-m-r-4 {
  margin-right: 4px;
}

.s-m-b-4 {
  margin-bottom: 4px;
}

.s-m-l-4 {
  margin-left: 4px;
}

.s-m-8 {
  margin: 8px;
}

.s-m-y-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.s-m-x-8 {
  margin-right: 8px;
  margin-left: 8px;
}

.s-m-t-8 {
  margin-top: 8px;
}

.s-m-r-8 {
  margin-right: 8px;
}

.s-m-b-8 {
  margin-bottom: 8px;
}

.s-m-l-8 {
  margin-left: 8px;
}

.s-m-12 {
  margin: 12px;
}

.s-m-y-12 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.s-m-x-12 {
  margin-right: 12px;
  margin-left: 12px;
}

.s-m-t-12 {
  margin-top: 12px;
}

.s-m-r-12 {
  margin-right: 12px;
}

.s-m-b-12 {
  margin-bottom: 12px;
}

.s-m-l-12 {
  margin-left: 12px;
}

.s-m-16 {
  margin: 16px;
}

.s-m-y-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.s-m-x-16 {
  margin-right: 16px;
  margin-left: 16px;
}

.s-m-t-16 {
  margin-top: 16px;
}

.s-m-r-16 {
  margin-right: 16px;
}

.s-m-b-16 {
  margin-bottom: 16px;
}

.s-m-l-16 {
  margin-left: 16px;
}

.s-m-24 {
  margin: 24px;
}

.s-m-y-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.s-m-x-24 {
  margin-right: 24px;
  margin-left: 24px;
}

.s-m-t-24 {
  margin-top: 24px;
}

.s-m-r-24 {
  margin-right: 24px;
}

.s-m-b-24 {
  margin-bottom: 24px;
}

.s-m-l-24 {
  margin-left: 24px;
}

.s-m-32 {
  margin: 32px;
}

.s-m-y-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.s-m-x-32 {
  margin-right: 32px;
  margin-left: 32px;
}

.s-m-t-32 {
  margin-top: 32px;
}

.s-m-r-32 {
  margin-right: 32px;
}

.s-m-b-32 {
  margin-bottom: 32px;
}

.s-m-l-32 {
  margin-left: 32px;
}

.s-m-48 {
  margin: 48px;
}

.s-m-y-48 {
  margin-top: 48px;
  margin-bottom: 48px;
}

.s-m-x-48 {
  margin-right: 48px;
  margin-left: 48px;
}

.s-m-t-48 {
  margin-top: 48px;
}

.s-m-r-48 {
  margin-right: 48px;
}

.s-m-b-48 {
  margin-bottom: 48px;
}

.s-m-l-48 {
  margin-left: 48px;
}

.s-m-64 {
  margin: 64px;
}

.s-m-y-64 {
  margin-top: 64px;
  margin-bottom: 64px;
}

.s-m-x-64 {
  margin-right: 64px;
  margin-left: 64px;
}

.s-m-t-64 {
  margin-top: 64px;
}

.s-m-r-64 {
  margin-right: 64px;
}

.s-m-b-64 {
  margin-bottom: 64px;
}

.s-m-l-64 {
  margin-left: 64px;
}

.s-m-128 {
  margin: 128px;
}

.s-m-y-128 {
  margin-top: 128px;
  margin-bottom: 128px;
}

.s-m-x-128 {
  margin-right: 128px;
  margin-left: 128px;
}

.s-m-t-128 {
  margin-top: 128px;
}

.s-m-r-128 {
  margin-right: 128px;
}

.s-m-b-128 {
  margin-bottom: 128px;
}

.s-m-l-128 {
  margin-left: 128px;
}

.c-b-neutral-10 {
  background-color: #e6eaec;
}

.c-b-neutral-20 {
  background-color: #ccd5d9;
}

.c-b-neutral-30 {
  background-color: #b3c0c6;
}

.c-b-neutral-40 {
  background-color: #99abb3;
}

.c-b-neutral-50 {
  background-color: #8097a0;
}

.c-b-neutral-60 {
  background-color: #66828c;
}

.c-b-neutral-70 {
  background-color: #4d6d79;
}

.c-b-neutral-80 {
  background-color: #4d6d79;
}

.c-b-neutral-90 {
  background-color: #1a4353;
}

.c-b-neutral-100 {
  background-color: #002e40;
}

.c-b-primary-10 {
  background-color: #e6f6fc;
}

.c-b-primary-20 {
  background-color: #ccedf9;
}

.c-b-primary-30 {
  background-color: #99dbf3;
}

.c-b-primary-40 {
  background-color: #66c9ed;
}

.c-b-primary-50 {
  background-color: #33b7e7;
}

.c-b-primary-60 {
  background-color: #00a5e1;
}

.c-b-primary-70 {
  background-color: #0095cb;
}

.c-b-primary-80 {
  background-color: #0084b4;
}

.c-b-primary-90 {
  background-color: #00739e;
}

.c-b-primary-100 {
  background-color: #005371;
}

.c-b-secondary-10 {
  background-color: #f8f9d8;
}

.c-b-secondary-20 {
  background-color: #f5f6c4;
}

.c-b-secondary-30 {
  background-color: #f1f3b0;
}

.c-b-secondary-40 {
  background-color: #eef19d;
}

.c-b-secondary-50 {
  background-color: #ebee89;
}

.c-b-secondary-60 {
  background-color: #e7eb75;
}

.c-b-secondary-70 {
  background-color: #e4e861;
}

.c-b-secondary-80 {
  background-color: #e0e54e;
}

.c-b-secondary-90 {
  background-color: #dde23a;
}

.c-b-secondary-100 {
  background-color: #c7cb34;
}

.c-b-success-10 {
  background-color: #e6f3f0;
}

.c-b-warning-10 {
  background-color: #fffbe6;
}

.c-b-error-10 {
  background-color: #fbeae7;
}

.c-b-info-10 {
  background-color: #e6f2f7;
}

.c-b-success-20 {
  background-color: #cce7e0;
}

.c-b-success-30 {
  background-color: #99cec2;
}

.c-b-success-40 {
  background-color: #66b6a3;
}

.c-b-success-50 {
  background-color: #339d85;
}

.c-b-success-60 {
  background-color: #008566;
}

.c-b-success-70 {
  background-color: #00785c;
}

.c-b-success-80 {
  background-color: #006a52;
}

.c-b-success-90 {
  background-color: #005d47;
}

.c-b-success-100 {
  background-color: #00503d;
}

.c-b-warning-20 {
  background-color: #fff1b8;
}

.c-b-warning-30 {
  background-color: #ffe58f;
}

.c-b-warning-40 {
  background-color: #ffd666;
}

.c-b-warning-50 {
  background-color: #ffc53d;
}

.c-b-warning-60 {
  background-color: #faad14;
}

.c-b-warning-70 {
  background-color: #d48806;
}

.c-b-warning-80 {
  background-color: #ad6800;
}

.c-b-warning-90 {
  background-color: #874d00;
}

.c-b-warning-100 {
  background-color: #613400;
}

.c-b-error-20 {
  background-color: #f8d6cf;
}

.c-b-error-30 {
  background-color: #f1ad9f;
}

.c-b-error-40 {
  background-color: #e9836f;
}

.c-b-error-50 {
  background-color: #e25a3f;
}

.c-b-error-60 {
  background-color: #db310f;
}

.c-b-error-70 {
  background-color: #c52c0e;
}

.c-b-error-80 {
  background-color: #af270c;
}

.c-b-error-90 {
  background-color: #99220b;
}

.c-b-error-100 {
  background-color: #831d09;
}

.c-b-info-20 {
  background-color: #cce5ef;
}

.c-b-info-30 {
  background-color: #b3d9e7;
}

.c-b-info-40 {
  background-color: #99ccdf;
}

.c-b-info-50 {
  background-color: #80bfd7;
}

.c-b-info-60 {
  background-color: #67b2ce;
}

.c-b-info-70 {
  background-color: #4da5c6;
}

.c-b-info-80 {
  background-color: #3499be;
}

.c-b-info-90 {
  background-color: #1a8cb6;
}

.c-b-info-100 {
  background-color: #017fae;
}

.c-t-neutral-10 {
  color: #e6eaec;
}

.c-t-neutral-20 {
  color: #ccd5d9;
}

.c-t-neutral-30 {
  color: #b3c0c6;
}

.c-t-neutral-40 {
  color: #99abb3;
}

.c-t-neutral-50 {
  color: #8097a0;
}

.c-t-neutral-60 {
  color: #66828c;
}

.c-t-neutral-70 {
  color: #4d6d79;
}

.c-t-neutral-80 {
  color: #4d6d79;
}

.c-t-neutral-90 {
  color: #1a4353;
}

.c-t-neutral-100 {
  color: #002e40;
}

.c-t-primary-10 {
  color: #e6f6fc;
}

.c-t-primary-20 {
  color: #ccedf9;
}

.c-t-primary-30 {
  color: #99dbf3;
}

.c-t-primary-40 {
  color: #66c9ed;
}

.c-t-primary-50 {
  color: #33b7e7;
}

.c-t-primary-60 {
  color: #00a5e1;
}

.c-t-primary-70 {
  color: #0095cb;
}

.c-t-primary-80 {
  color: #0084b4;
}

.c-t-primary-90 {
  color: #00739e;
}

.c-t-primary-100 {
  color: #005371;
}

.c-t-secondary-10 {
  color: #f8f9d8;
}

.c-t-secondary-20 {
  color: #f5f6c4;
}

.c-t-secondary-30 {
  color: #f1f3b0;
}

.c-t-secondary-40 {
  color: #eef19d;
}

.c-t-secondary-50 {
  color: #ebee89;
}

.c-t-secondary-60 {
  color: #e7eb75;
}

.c-t-secondary-70 {
  color: #e4e861;
}

.c-t-secondary-80 {
  color: #e0e54e;
}

.c-t-secondary-90 {
  color: #dde23a;
}

.c-t-secondary-100 {
  color: #c7cb34;
}

.c-t-success-10 {
  color: #e6f3f0;
}

.c-t-warning-10 {
  color: #fffbe6;
}

.c-t-error-10 {
  color: #fbeae7;
}

.c-t-info-10 {
  color: #e6f2f7;
}

.c-t-success-20 {
  color: #cce7e0;
}

.c-t-success-30 {
  color: #99cec2;
}

.c-t-success-40 {
  color: #66b6a3;
}

.c-t-success-50 {
  color: #339d85;
}

.c-t-success-60 {
  color: #008566;
}

.c-t-success-70 {
  color: #00785c;
}

.c-t-success-80 {
  color: #006a52;
}

.c-t-success-90 {
  color: #005d47;
}

.c-t-success-100 {
  color: #00503d;
}

.c-t-warning-20 {
  color: #fff1b8;
}

.c-t-warning-30 {
  color: #ffe58f;
}

.c-t-warning-40 {
  color: #ffd666;
}

.c-t-warning-50 {
  color: #ffc53d;
}

.c-t-warning-60 {
  color: #faad14;
}

.c-t-warning-70 {
  color: #d48806;
}

.c-t-warning-80 {
  color: #ad6800;
}

.c-t-warning-90 {
  color: #874d00;
}

.c-t-warning-100 {
  color: #613400;
}

.c-t-error-20 {
  color: #f8d6cf;
}

.c-t-error-30 {
  color: #f1ad9f;
}

.c-t-error-40 {
  color: #e9836f;
}

.c-t-error-50 {
  color: #e25a3f;
}

.c-t-error-60 {
  color: #db310f;
}

.c-t-error-70 {
  color: #c52c0e;
}

.c-t-error-80 {
  color: #af270c;
}

.c-t-error-90 {
  color: #99220b;
}

.c-t-error-100 {
  color: #831d09;
}

.c-t-info-20 {
  color: #cce5ef;
}

.c-t-info-30 {
  color: #b3d9e7;
}

.c-t-info-40 {
  color: #99ccdf;
}

.c-t-info-50 {
  color: #80bfd7;
}

.c-t-info-60 {
  color: #67b2ce;
}

.c-t-info-70 {
  color: #4da5c6;
}

.c-t-info-80 {
  color: #3499be;
}

.c-t-info-90 {
  color: #1a8cb6;
}

.c-t-info-100 {
  color: #017fae;
}

.breadcrumbs-dropdown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 500;
}

.breadcrumbs-dropdown:hover {
  color: #33b7e7;
}

.breadcrumbs-dropdown:active {
  color: #0095cb;
}

.dropdown-button-text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.card-title.centered {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.t-a-l {
  text-align: left;
}

.t-a-c {
  text-align: center;
}

.t-a-r {
  text-align: right;
}

.t-a-j {
  text-align: justify;
}

.feature-grid {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.feature-grid-2 {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1.5fr;
  grid-template-columns: 1fr 1.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.feature-grid-3 {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1.5fr 1fr;
  grid-template-columns: 1.5fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.hero-grid {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 0.75fr 1fr;
  grid-template-columns: 0.75fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.hero-image {
  border-radius: 20px;
}

.hero-grid-2 {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 48px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1.5fr;
  grid-template-columns: 1fr 1.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.hero-title-centered {
  width: 60%;
  margin-right: auto;
  margin-bottom: 48px;
  margin-left: auto;
  text-align: center;
}

.hero-title-centered.s-m-b-0 {
  margin-bottom: 0px;
}

.wrapper-l {
  padding-top: 128px;
  padding-bottom: 128px;
}

.ctas-wrapper {
  margin-top: 1.5rem;
}

.ctas-wrapper.s-m-0 {
  margin-top: 0rem;
}

.faq-item-wrapper {
  margin-bottom: 24px;
}

.faq-item-wrapper.box {
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 6px;
  background-color: #ccd5d9;
}

.faq-item-wrapper.box.last {
  margin-bottom: 0px;
}

.faq-item-wrapper-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 24px;
  padding-bottom: 24px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-top: 1px solid #99abb3;
}

.faq-col-1 {
  width: 40%;
  padding-right: 16px;
}

.faq-col-2 {
  width: 60%;
}

.faq-question {
  margin-bottom: 8px;
  font-weight: 600;
}

.pricing-card-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 20px;
  background-color: #e6eaec;
  box-shadow: inset 0 0 0 1px #99abb3;
}

.pricing-card-1.pro {
  box-shadow: inset 0 0 0 2px #00a5e1;
}

.pricing-card-1.enterprise {
  box-shadow: inset 0 0 0 2px #e7eb75;
}

.pricing-card-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #99abb3;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #e6eaec;
}

.price {
  margin-bottom: 1rem;
  font-size: 2.75rem;
  line-height: 1;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.currency-span {
  padding-right: 4px;
  color: #4d6d79;
  font-size: 1.5rem;
  font-weight: 500;
}

.price-2 {
  margin-bottom: 1.5rem;
  color: #002e40;
  font-size: 2.25rem;
  line-height: 1.25;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.price-title {
  line-height: 40px;
  font-weight: 600;
}

.currency-span-2 {
  padding-right: 4px;
  color: #4d6d79;
  font-size: 1.25rem;
}

.pricing-2 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto auto auto auto auto auto auto;
  grid-template-rows: auto auto auto auto auto auto auto auto auto;
}

.pricing-3 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
  grid-template-rows: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
  border-style: solid;
  border-width: 1px;
  border-color: #8097a0;
  border-radius: 6px;
  background-color: #e6eaec;
  text-align: center;
}

.tooltip-text {
  display: inline-block;
  margin-right: 4px;
  font-weight: 500;
}

.testimonials-logos-grid {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.testimonial-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 48px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 6px;
  background-color: #ccd5d9;
}

.testimonial-box.light {
  border-style: solid;
  border-width: 1px;
  border-color: #99abb3;
  background-color: #e6eaec;
}

.testimonial-box-2 {
  padding: 48px 128px;
  border-radius: 6px;
  background-color: #ccd5d9;
  text-align: center;
}

.alert-banner {
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #f8f9d8;
  font-weight: 500;
}

.alert-content-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.contact-link {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  color: #4d6d79;
  font-weight: 400;
  text-decoration: none;
}

.contact-link:hover {
  color: #00a5e1;
}

.contact-grid {
  padding: 64px;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-column-gap: 48px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 20px;
  background-color: #ccd5d9;
}

.contact-image {
  height: 800px;
  -o-object-fit: cover;
  object-fit: cover;
}

.collapse-item-2 {
  padding-right: 16px;
  padding-left: 16px;
  cursor: pointer;
}

.modal-buttons-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 16px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.testimonial-logo-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 128px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ccd5d9;
}

.hero-video {
  border-radius: 20px;
}

.wrapper-s {
  padding-top: 32px;
  padding-bottom: 32px;
}

.hero-grid-3 {
  padding-top: 64px;
  padding-bottom: 64px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.hero-form-wrapper {
  margin-right: 64px;
  margin-left: 64px;
  padding: 48px;
  border-style: solid;
  border-width: 1px;
  border-color: #99abb3;
  border-radius: 6px;
  background-color: #e6eaec;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
}

.long-content-menu-left {
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  -ms-grid-columns: 0.5fr 1.5fr;
  grid-template-columns: 0.5fr 1.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.long-content-center {
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.class-label {
  display: inline-block;
  padding-right: 4px;
  padding-left: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #3499be;
  border-radius: 4px;
  background-color: #4da5c6;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 400;
}

.class-label.body {
  border-color: #86285f;
  background-color: #b5518c;
}

.class-label-wrapper {
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
  cursor: pointer;
}

.backtotop-wrapper {
  position: fixed;
  left: auto;
  top: auto;
  right: 16px;
  bottom: 48px;
  z-index: 999;
}

.hidden {
  display: none;
}

.color-block {
  width: 100%;
  height: 48px;
}

.alert-banner-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #0095cb;
  color: #e6eaec;
  line-height: 1.5;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
}

.brand-logo-image {
  height: 3rem;
}

.customize-page-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.customize-instructions {
  width: 35%;
  height: 100vh;
  border-right: 1px solid #8097a0;
}

.customize-preview {
  width: 75%;
  height: 100vh;
}

.style-wrapper {
  position: relative;
  margin-bottom: 24px;
  padding: 24px;
  border-style: dashed;
  border-width: 1px;
  border-color: #8097a0;
  border-radius: 4px;
  background-color: #ccd5d9;
}

.component-wrapper-2 {
  position: relative;
  margin-top: 64px;
  padding: 24px;
  border-style: dashed;
  border-width: 2px;
  border-color: #002e40;
  border-radius: 4px;
  background-color: #e6eaec;
}

.component-wrapper-label-2 {
  position: absolute;
  left: 0%;
  top: -40px;
  right: auto;
  bottom: auto;
  display: block;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #002e40;
  color: #e6eaec;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
}

.component-wrapper-label-2:hover {
  opacity: 0.8;
}

.tabs-menu {
  box-shadow: inset 0 -1px 0 0 #8097a0;
}

.docsui {
  display: block;
}

.hero-section.hero-big {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.7)), to(rgba(0, 0, 0, 0.7))), url('../images/Image-Placeholder-2.svg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../images/Image-Placeholder-2.svg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.testimonials-section {
  overflow: hidden;
}

.colors-transfer-grid {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto auto auto auto auto auto;
  grid-template-rows: auto auto auto auto auto auto auto auto;
}

.color-table-label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 48px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.color-table-label.color-group-name {
  padding-right: 8px;
  padding-left: 8px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: right;
}

.color-table-swatch {
  height: 48px;
}

.color-table-swatch.c-b-neutral-90 {
  background-color: #1a4353;
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -12px;
  margin-left: -12px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.column {
  width: 50%;
  padding-right: 12px;
  padding-left: 12px;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.lightbox-centered {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.section-box-wrapper {
  padding: 64px;
  border-radius: 20px;
  background-color: #ccd5d9;
}

.section-box-wrapper.c-b-neutral-10 {
  background-color: #e6eaec;
}

.feature-content-wrapper.right {
  padding-left: 64px;
}

.feature-content-wrapper.left {
  padding-right: 64px;
}

.dropdown-fluid {
  display: block;
}

.dropdown-menu-1 {
  z-index: 8;
  display: none;
  cursor: pointer;
}

.dropdown-menu-1:active {
  background-color: #b3d6f9;
}

.dropdown-menu-1.w--open {
  overflow: hidden;
  width: 200px;
  margin-top: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #99abb3;
  border-radius: 6px;
  background-color: #e6eaec;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  color: #1a4353;
}

.dropdown-menu-item---navbar {
  padding: 8px 16px;
  background-color: transparent;
}

.dropdown-menu-item---navbar:hover {
  background-color: #e6f6fc;
}

.dropdown-menu-item---navbar.w--current {
  background-color: #2f54eb;
  color: #e6eaec;
}

.dropdown-menu-item---navbar.dark {
  color: #66828c;
}

.dropdown-menu-item---navbar.dark:hover {
  background-color: hsla(0, 0%, 100%, 0.05);
}

.dropdown-menu-item---navbar.dark:active {
  background-color: hsla(0, 0%, 100%, 0.08);
}

.dropdown-menu-item---navbar.dark:focus {
  background-color: hsla(0, 0%, 100%, 0.08);
}

.feature-icon-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 48px;
  height: 48px;
  margin-bottom: 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 6px;
  background-color: #e6f6fc;
  color: #00a5e1;
}

.feature-icon-wrapper.dark {
  background-color: #1a4353;
  color: #e6eaec;
}

.feature-icon-wrapper.alt {
  width: 32px;
  height: 32px;
  margin-right: 16px;
  margin-bottom: 0px;
}

.text-wrapper.centered {
  text-align: center;
}

.navbar-buttons {
  margin-left: 8px;
}

.navbar-buttons.mobile {
  display: none;
}

.avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}

.avatar.default {
  width: 44px;
  height: 44px;
  border-style: solid;
  border-width: 2px;
  border-color: #e6eaec;
  box-shadow: 0 0 0 3px #00a5e1;
}

.avatar.default.group {
  margin-right: -8px;
  box-shadow: none;
}

.avatar.small {
  width: 32px;
  height: 32px;
  border-style: solid;
  border-width: 1px;
  border-color: #e6eaec;
  box-shadow: 0 0 0 3px #00a5e1;
}

.avatar.small.group {
  margin-right: -8px;
  box-shadow: none;
}

.avatar.large {
  width: 56px;
  height: 56px;
  border-style: solid;
  border-width: 2px;
  border-color: #e6eaec;
  box-shadow: 0 0 0 3px #00a5e1;
}

.avatar.large.group {
  margin-right: -16px;
  box-shadow: none;
}

.avatar-wrapper.horizontal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.avatar-wrapper.vertical {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.avatar-label-text {
  margin-left: 1rem;
}

.avatar-label-text.centered {
  margin-top: 8px;
  margin-left: 0px;
  text-align: center;
}

.code-span {
  display: inline-block;
  padding-right: 4px;
  padding-left: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #8097a0;
  border-radius: 4px;
  background-color: #b3c0c6;
  font-family: Inter, sans-serif;
  color: #002e40;
  font-size: 0.875rem;
}

.custom-code-embeds {
  display: none;
}

.lc-countdown {
  display: inline;
  margin-left: 4px;
}

.lc-countdown.big {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.lc-countdown-wrapper {
  display: inline-block;
}

.lc-countdown-wrapper.vertical {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 12px;
  padding-left: 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lc-countdown-count {
  display: inline-block;
  margin-right: 2px;
  font-weight: 600;
}

.lc-countdown-count.big {
  font-size: 2.375rem;
  line-height: 1.25;
  font-weight: 600;
}

.lc-countdown-label {
  display: inline-block;
  margin-right: 4px;
}

.cookie-notification {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 16px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #99abb3;
  border-radius: 6px;
  background-color: #e6eaec;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  color: #1a4353;
}

.countdown-banner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #c52c0e;
  color: #e6eaec;
  line-height: 1.5;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
}

.countdown-banner.c-b-primary-10 {
  background-color: #e6f6fc;
}

.dropdown-menu-breadcrumbs {
  z-index: 8;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 6px;
  background-color: #e6eaec;
}

.dropdown-menu-breadcrumbs.w--open {
  overflow: hidden;
  width: 200px;
  margin-top: 8px;
  background-color: #e6eaec;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  color: #1a4353;
}

.tabs-vertical {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.tabs-menu-vertical {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 35%;
  margin-right: 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.tabs-content-vertical {
  width: 65%;
  margin-left: 12px;
}

.tab-vertical {
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 16px 24px;
  border-radius: 6px;
  background-color: transparent;
}

.tab-vertical:hover {
  background-color: #ccd5d9;
}

.tab-vertical.w--current {
  padding: 16px 24px;
  background-color: #e6f6fc;
  cursor: pointer;
}

.section-title-column {
  width: 50%;
  padding-right: 12px;
  padding-left: 12px;
}

.tabs-switch {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.tabs-switch-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-bottom: 32px;
  margin-left: auto;
  padding: 4px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  background-color: #b3c0c6;
  text-align: center;
}

.tab-switch {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 24px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  background-color: transparent;
  color: #4d6d79;
  font-weight: 500;
  text-align: center;
}

.tab-switch.w--current {
  background-color: #e6eaec;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.08);
  color: #002e40;
}

.list-vertical {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0rem;
  padding-left: 0rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.stars-wrapper {
  margin-bottom: 8px;
}

.testimonials-slider {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.slide-nav {
  display: none;
}

.testimonials-mask {
  overflow: visible;
  max-width: 580px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.testimonials-slide {
  margin-right: 24px;
}

.slider-left-arrow {
  left: -20px;
  width: 56px;
  height: 56px;
  min-height: 56px;
  min-width: 56px;
  border-radius: 100%;
  background-color: #002e40;
}

.slider-left-arrow:hover {
  background-color: #00a5e1;
}

.slider-arrow-icon {
  font-size: 1.5rem;
}

.slider-right-arrow {
  right: -18px;
  width: 56px;
  height: 56px;
  min-height: 56px;
  min-width: 56px;
  border-radius: 100%;
  background-color: #002e40;
}

.slider-right-arrow:hover {
  background-color: #00a5e1;
}

.countup-number {
  margin-bottom: 0.25rem;
  color: #002e40;
  font-size: 2.75rem;
  line-height: 1.25;
  font-weight: 600;
}

.countup-number.alt {
  color: #00a5e1;
  font-size: 2.25rem;
}

.countup {
  color: #1a4353;
  text-align: center;
}

.countup.alt {
  padding: 24px;
  border-radius: 6px;
  background-color: #ccd5d9;
}

.countup-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.countup-plus {
  margin-bottom: 0.25rem;
  font-size: 2.75rem;
  line-height: 1.25;
  font-weight: 600;
}

.countup-plus.alt {
  color: #00a5e1;
  font-size: 2.25rem;
}

.lc-text-replace {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.dropdown-menu-separator {
  width: 100%;
  height: 1px;
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: #99abb3;
}

.dropdown-link-w-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 6px 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #4d6d79;
  text-decoration: none;
}

.dropdown-link-w-icon:hover {
  background-color: #ccd5d9;
}

.dropdown-link-w-icon:active {
  background-color: #b3c0c6;
}

.slide-1 {
  height: 100%;
  background-color: #e6f6fc;
}

.slider {
  height: 100%;
  background-color: transparent;
}

.left-arrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.right-arrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #1a4353;
}

.slide-2 {
  background-color: #ccd5d9;
}

.slide-3 {
  background-color: #002e40;
}

.megamenu {
  position: absolute;
  left: 0%;
  top: 68px;
  right: 0%;
  bottom: auto;
  display: none;
  border-bottom: 1px solid #99abb3;
  background-color: #e6eaec;
}

.megamenu.dark {
  border-bottom-color: #1a4353;
  background-color: #002e40;
}

.megamenu-grid {
  padding-top: 24px;
  padding-bottom: 48px;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.nav-megamenu-trigger {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  color: #4d6d79;
  text-decoration: none;
  cursor: pointer;
}

.nav-megamenu-trigger:hover {
  color: #00a5e1;
}

.nav-megamenu-trigger:active {
  background-color: #ccd5d9;
}

.nav-megamenu-trigger:focus {
  background-color: #ccd5d9;
}

.nav-megamenu-trigger.w--open {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.nav-megamenu-trigger.color-inverse {
  color: #66828c;
}

.nav-megamenu-trigger.color-inverse:hover {
  color: #e6eaec;
}

.nav-megamenu-trigger.color-inverse:active {
  background-color: #1a4353;
}

.nav-megamenu-trigger.color-inverse:focus {
  background-color: #1a4353;
  color: #e6eaec;
}

.megamenu-dropdown {
  border-radius: 6px;
}

.megamenu-mobile {
  display: none;
  border-bottom: 1px solid #99abb3;
  background-color: #e6eaec;
}

.nav-megamenu-list {
  margin-bottom: 0rem;
}

.megamenu-2 {
  position: absolute;
  left: 0%;
  top: 68px;
  right: 0%;
  bottom: auto;
  display: none;
  border-bottom: 1px solid #99abb3;
  background-color: #e6eaec;
}

.megamenu-2.dark {
  display: none;
  border-bottom-color: #1a4353;
  background-color: #002e40;
}

.megamenu-nav-link-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 24px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 6px;
  background-color: transparent;
  font-size: 0.875rem;
  text-decoration: none;
}

.megamenu-nav-link-box:hover {
  background-color: #ccd5d9;
}

.megamenu-nav-link-box:active {
  background-color: #b3c0c6;
}

.megamenu-nav-link-box:focus {
  background-color: #ccd5d9;
  box-shadow: 0 0 0 2px #e6eaec, 0 0 0 5px #00a5e1;
}

.megamenu-nav-link-box.dark:hover {
  background-color: hsla(0, 0%, 100%, 0.05);
}

.megamenu-nav-link-box.dark:active {
  background-color: #1a4353;
}

.megamenu-nav-link-box.dark:focus {
  background-color: #1a4353;
  box-shadow: 0 0 0 2px #002e40, 0 0 0 5px #e6eaec;
}

.megamenu-grid-2 {
  margin-right: -24px;
  margin-left: -24px;
  padding-top: 24px;
  padding-bottom: 48px;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.megamenu-navlink-text-wrapper {
  margin-left: 16px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.preview-area {
  height: 400px;
  background-color: #b3c0c6;
}

.tabs-content {
  overflow: visible;
}

.collapse-wrapper {
  max-width: 640px;
  margin-right: auto;
  margin-left: auto;
}

.testimonial-box-small {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 24px;
  border-style: solid;
  border-width: 1px;
  border-color: #99abb3;
  border-radius: 6px;
  background-color: #e6eaec;
  font-size: 0.875rem;
  line-height: 1.5;
}

.cta-box {
  padding: 64px;
  border-radius: 20px;
}

.grid-blog-1 {
  grid-auto-flow: row;
  grid-column-gap: 80px;
  grid-row-gap: 80px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.card-image-blog-big {
  border-radius: 6px;
}

.card-blog-text-wrapper {
  padding-right: 24px;
  padding-left: 24px;
}

.card-blog-big-grid {
  justify-items: stretch;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 20px;
  grid-row-gap: 24px;
  -ms-grid-columns: 2fr 1fr;
  grid-template-columns: 2fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.feature-list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  background-color: #e6eaec;
}

.feature-list-item.alt {
  margin-top: 0px;
  margin-bottom: 0px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
}

.feature-grid-4 {
  grid-column-gap: 48px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1.75fr 3fr;
  grid-template-columns: 1.75fr 3fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.feature-grid-4.reversed {
  -ms-grid-columns: 3fr 1.75fr;
  grid-template-columns: 3fr 1.75fr;
}

.feature-section-image {
  margin-bottom: 32px;
}

.feature-section-image.s-m-b-0 {
  margin-bottom: 0px;
}

.l-g-2x1 {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 2fr 1fr;
  grid-template-columns: 2fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.l-g-1x2 {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 2fr;
  grid-template-columns: 1fr 2fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.countup-number-big {
  display: inline-block;
  color: #00a5e1;
  font-size: 2.75rem;
  line-height: 1.25;
  font-weight: 600;
}

.countup-wrapper-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 8px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.pricing-card-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-style: solid;
  border-width: 1px;
  border-color: #8097a0;
  border-radius: 6px;
  background-color: #e6eaec;
}

.pricing-card-3.pro {
  border-width: 2px;
  border-color: #00a5e1;
}

.footer-address {
  margin-top: 44px;
}

.wrapper-box {
  padding: 64px;
  background-color: #ccd5d9;
}

.age-verification-cont {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(7, 3, 3, 0.8);
  opacity: 0;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.age-verification-form {
  position: relative;
  overflow: hidden;
  margin-bottom: 0px;
  border-radius: 6px;
  background-color: #e6eaec;
}

.age-verification-wrapper {
  position: relative;
  padding: 48px;
}

.verify-age-alert {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  display: block;
  width: 100%;
  padding: 4px 10px;
  background-color: #fbeae7;
  opacity: 0;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  color: #c52c0e;
  text-align: center;
}

.dropdown {
  display: block;
}

.tags {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.tags-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.button-content-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.breadcrumbs-back-mobile {
  display: none;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.multiple-select-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #4d6d79;
}

.text-wrapper-l {
  padding-right: 64px;
}

.testimonial-author {
  margin-bottom: 0rem;
  color: #002e40;
  font-weight: 600;
}

.image-full-width {
  width: 100%;
  height: 480px;
  border-radius: 20px;
  -o-object-fit: cover;
  object-fit: cover;
}

.hero-image-big-text-wrapper {
  margin-bottom: 48px;
}

.image-responsive {
  width: 100%;
  height: 480px;
  -o-object-fit: cover;
  object-fit: cover;
}

.countup-title {
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.feature-list-items {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.pricing-grid-1 {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.pricing-list-title {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.pricing-card-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-radius: 20px;
  background-color: #e6eaec;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.pricing-card-2-header {
  padding: 32px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  background-color: #b3c0c6;
}

.pricing-card-2-header.pro {
  background-color: #ccedf9;
  color: #00a5e1;
}

.pricing-card-2-header.enterprise {
  background-color: #f8f9d8;
  color: #e0e54e;
}

.pricing-2-card-title {
  margin-bottom: 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;
}

.price-month-span {
  font-size: 1.125rem;
}

.pricing-card-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.pricing-card-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-radius: 20px;
  background-color: #1a4353;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.pricing-card-4.pro {
  box-shadow: inset 0 0 0 2px #e6eaec, 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.pricing-card-4-header {
  padding: 32px 32px 0px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.pricing-3-card-title {
  margin-bottom: 0.75rem;
  color: #e6eaec;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;
}

.l-g-1-col {
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

.testimonials-feature-text-wrapper {
  padding-right: 64px;
}

.long-content-menu-right {
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-column-gap: 128px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1.5fr 0.5fr;
  grid-template-columns: 1.5fr 0.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.overview-list-grid {
  margin-top: 120px;
  padding: 32px;
  grid-column-gap: 24px;
  grid-row-gap: 32px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 20px;
  background-color: #ccd5d9;
}

.docs-section {
  padding-top: 64px;
  padding-bottom: 64px;
}

.docs-section.first {
  padding-top: 0px;
}

.docs-section.last {
  padding-bottom: 0px;
}

.input-description {
  margin-bottom: 0.75rem;
}

.footer-docs {
  border-top: 1px solid #99abb3;
  background-color: #ccd5d9;
}

.footer-bottom-docs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 32px;
  padding-top: 32px;
  padding-bottom: 32px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #4d6d79;
}

.footer-docs-embed {
  display: none;
}

.footer-docs-navigation {
  margin-left: -16px;
}

.lc-embed {
  display: none;
}

.style-guide-content-section {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.testimonial-logo {
  width: 50%;
  opacity: 0.5;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.megamenu-item-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 48px;
  height: 48px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 6px;
  background-color: #e6f6fc;
  color: #00a5e1;
}

.megamenu-item-icon.dark {
  background-color: #4d6d79;
  color: #e6eaec;
}

.megamenu-link-description {
  margin-bottom: 0rem;
}

.megamenu-link-description.dark {
  color: #4d6d79;
}

.megamenu-link-title {
  margin-bottom: 0.5rem;
  color: #002e40;
  font-weight: 600;
}

.megamenu-link-title.c-t-neutral-10 {
  color: #e6eaec;
}

.color-text-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 8px 16px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.color-text-block.dark {
  background-color: #000;
}

.note-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #ebebeb;
}

.small-text {
  margin-bottom: 0px;
  font-size: 12px;
  line-height: 1;
}

.c-b-neutral-5 {
  background-color: #fff;
}

.c-t-neutral-5 {
  color: #fff;
}

.non-compliant {
  display: none;
}

.animation-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.animation-play-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.animation-timeline-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.timeline {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  border-radius: 20px;
  background-color: #e6eaec;
}

.timeline-circle {
  width: 5%;
  height: 100%;
  border-radius: 20px;
  background-color: #002e40;
}

.animation-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.example-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.example {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.example-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
}

.heading-wrapper {
  margin-bottom: 40px;
}

.form-submission-icon-complete {
  width: 60px;
  height: 60px;
  margin-right: -15px;
  margin-left: -30px;
}

.container-heading {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1;
}

.button-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.class-name {
  margin-top: 20px;
  color: #a4a4a4;
  font-size: 12px;
  text-align: center;
}

.form-submission-button-text {
  line-height: 1;
}

.button-container-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-column-gap: 50px;
  grid-row-gap: 50px;
}

.form-submission-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  padding-right: 32px;
  padding-left: 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  border-style: solid;
  border-width: 3px;
  border-color: #017fae;
  border-radius: 30px;
  background-color: #017fae;
  color: #fff;
  text-decoration: none;
}

.form-submission-button.is-submitted {
  border-color: #cce5ef;
  background-color: #cce5ef;
}

.button-primary-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  padding-right: 50px;
  padding-left: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #017fae;
  border-radius: 30px;
  background-color: #017fae;
  box-shadow: 1px 1px 5px 1px #c4c4c4;
  -webkit-transition: box-shadow 200ms ease, border 200ms ease, background-color 200ms ease;
  transition: box-shadow 200ms ease, border 200ms ease, background-color 200ms ease;
  color: #fff;
  line-height: 1;
  text-decoration: none;
}

.button-primary-1:hover {
  border-color: #002e40;
  background-color: #002e40;
}

.button-primary-1:focus {
  border-width: 2px;
  border-color: #fff;
  background-color: #002e40;
  box-shadow: 0 0 5px 3px #73c1dd;
}

.form-submission-icon-submitted {
  width: 80px;
  height: 80px;
  margin-right: -20px;
  margin-left: -30px;
}

.parallax-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.parallax-main-image {
  position: relative;
  z-index: 2;
}

.parallax-left-floating-image {
  position: absolute;
  left: -170px;
  top: auto;
  right: auto;
  bottom: -140px;
  z-index: 4;
}

.parallax-right-floating-image {
  position: absolute;
  left: auto;
  top: auto;
  right: -40px;
  bottom: -170px;
  z-index: 3;
}

.parallax-rear-floating-image {
  position: absolute;
  left: -20px;
  top: auto;
  right: auto;
  bottom: -100px;
  z-index: 1;
}

.progress-wrapper {
  position: fixed;
  z-index: 7;
  width: 100%;
  height: 10px;
}

.progress-bar {
  width: 0%;
  height: 100%;
  background-color: #002e40;
}

html.w-mod-js *[data-ix="tooltip-hover"] {
  display: inline-block;
}

@media screen and (max-width: 991px) {
  .container {
    padding-right: 24px;
    padding-left: 24px;
  }

  .container.container-navbar {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .container.megamenu-container {
    padding-right: 8px;
    padding-left: 8px;
  }

  .body-text.m.s-m-b-0.sh-m-b-24 {
    margin-bottom: 1.5rem;
  }

  .section-title {
    width: 100%;
    margin-bottom: 32px;
  }

  .section-title.centered {
    width: 75%;
  }

  .section-title._2-columns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .l-g-3-col {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  .l-g-3-col.lt-g-2-col {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .l-g-3-col.lt-g-1-col {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .l-g-3-col.cards {
    grid-row-gap: 32px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .l-g-4-col {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .button.medium {
    font-size: 16px;
  }

  .button.small.cookies {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .dropdown-menu-navbar {
    position: relative;
  }

  .dropdown-menu-navbar.w--open {
    position: relative;
    margin-top: 0px;
    border-style: none;
    background-color: #ccd5d9;
    box-shadow: none;
    text-align: center;
  }

  .dropdown-menu-navbar.dark.w--open {
    border-style: none;
  }

  .l-g-2-col {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  .l-g-2-col.lt-g-1-col {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .input {
    font-size: 16px;
  }

  .cta-wrapper {
    width: 80%;
  }

  .card.card-horizontal {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .card-horizontal-text-wrapper {
    margin-left: 0px;
    padding: 0px;
  }

  .side-nav-menu {
    position: static;
  }

  .footer {
    padding-right: 0px;
    padding-left: 0px;
  }

  .material-icons.icon-left.icon-collapse {
    margin-top: 1px;
  }

  .material-icons.icon-left.icon-alert-banner.c-t-secondary-60 {
    margin-top: 4px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .material-icons.caret-down.megamenu-icon {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .navbar-menu {
    padding-right: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    background-color: #e6eaec;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
  }

  .navbar-menu.dark {
    background-color: #002e40;
  }

  .nav-link {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
  }

  .nav-link.nav-link-dropdown {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-right: 16px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .footer-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    text-align: center;
  }

  .brand-logo {
    margin-right: 0px;
    padding-left: 0px;
  }

  .footer-logo.s-m-r-24.st-m-0 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .vertical-menu-title {
    margin-bottom: 12px;
  }

  .vertical-menu-list-item {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .footer-bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .vertical-menu-link.color-inverse {
    color: #4d6d79;
  }

  .vertical-menu-link.color-inverse:hover {
    color: #e6eaec;
  }

  .sm-icon-link {
    margin-right: 16px;
  }

  .sm-icon-link.s-m-r-0 {
    margin-right: 0px;
  }

  .footer-grid-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    text-align: center;
  }

  .footer-grid-3 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    text-align: center;
  }

  .footer-top-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer-bottom-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer-2 {
    padding-right: 20px;
    padding-bottom: 32px;
    padding-left: 20px;
  }

  .grid-cta {
    justify-items: stretch;
    grid-column-gap: 16px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    text-align: center;
  }

  .cta-section-text-wrapper {
    padding-right: 0px;
  }

  .cta-section-form-wrapper {
    width: 480px;
    margin-right: auto;
    margin-left: auto;
  }

  .footer-menu-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  .menu-button.w--open {
    background-color: #e6f6fc;
    color: #00a5e1;
  }

  .menu-button.color-inverse {
    color: #e6eaec;
  }

  .menu-button.color-inverse.w--open {
    background-color: #1a4353;
  }

  .navbar-menu-right {
    z-index: -5000;
    display: none;
    padding-right: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    background-color: #e6eaec;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
  }

  .navbar-menu-mobile {
    display: block;
  }

  .collapse-text {
    display: none;
  }

  .style-guide-page-header {
    padding: 31px 0px 0px;
  }

  .grid-styleguide---buttons {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  .style-guide-page-header-text {
    width: 100%;
    padding-top: 88px;
    padding-bottom: 48px;
  }

  .navbar-vertical {
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    z-index: 5000;
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    background-color: transparent;
  }

  .navbar-vertical-brand {
    display: none;
  }

  .navbar-vertical-content-wrapper {
    display: block;
    overflow: scroll;
    width: 70%;
    padding-top: 8px;
    padding-bottom: 200px;
    background-color: #e6eaec;
  }

  .navbar-vertical---mobile {
    display: block;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #8097a0;
  }

  .navbar-vertical-overlay {
    position: relative;
    z-index: 5000;
    display: none;
    width: 100%;
    height: 100vh;
  }

  .navbar-vertical-bg {
    display: block;
    width: 30%;
    height: 100vh;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .nav-tabs-container {
    overflow: scroll;
  }

  .style-guide-page-section-wrapper.narrow {
    max-width: 100%;
  }

  .nav-tab {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .container-fluid {
    padding-right: 16px;
    padding-left: 16px;
  }

  .st-p-0 {
    padding: 0px;
  }

  .st-p-y-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .st-p-x-0 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .st-p-t-0 {
    padding-top: 0px;
  }

  .st-p-r-0 {
    padding-right: 0px;
  }

  .st-p-b-0 {
    padding-bottom: 0px;
  }

  .st-p-l-0 {
    padding-left: 0px;
  }

  .st-p-4 {
    padding: 4px;
  }

  .st-p-y-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .st-p-x-4 {
    padding-right: 4px;
    padding-left: 4px;
  }

  .st-p-t-4 {
    padding-top: 4px;
  }

  .st-p-r-4 {
    padding-right: 4px;
  }

  .st-p-b-4 {
    padding-bottom: 4px;
  }

  .st-p-l-4 {
    padding-left: 4px;
  }

  .st-p-8 {
    padding: 8px;
  }

  .st-p-y-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .st-p-x-8 {
    padding-right: 8px;
    padding-left: 8px;
  }

  .st-p-t-8 {
    padding-top: 8px;
  }

  .st-p-r-8 {
    padding-right: 8px;
  }

  .st-p-b-8 {
    padding-bottom: 8px;
  }

  .st-p-l-8 {
    padding-left: 8px;
  }

  .st-p-12 {
    padding: 12px;
  }

  .st-p-y-12 {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .st-p-x-12 {
    padding-right: 12px;
    padding-left: 12px;
  }

  .st-p-t-12 {
    padding-top: 12px;
  }

  .st-p-r-12 {
    padding-right: 12px;
  }

  .st-p-b-12 {
    padding-bottom: 12px;
  }

  .st-p-l-12 {
    padding-left: 12px;
  }

  .st-p-16 {
    padding: 16px;
  }

  .st-p-y-16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .st-p-x-16 {
    padding-right: 16px;
    padding-left: 16px;
  }

  .st-p-t-16 {
    padding-top: 16px;
  }

  .st-p-r-16 {
    padding-right: 16px;
  }

  .st-p-b-16 {
    padding-bottom: 16px;
  }

  .st-p-l-16 {
    padding-left: 16px;
  }

  .st-p-24 {
    padding: 24px;
  }

  .st-p-y-24 {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .st-p-x-24 {
    padding-right: 24px;
    padding-left: 24px;
  }

  .st-p-t-24 {
    padding-top: 24px;
  }

  .st-p-r-24 {
    padding-right: 24px;
  }

  .st-p-b-24 {
    padding-bottom: 24px;
  }

  .st-p-l-24 {
    padding-left: 24px;
  }

  .st-p-32 {
    padding: 32px;
  }

  .st-p-y-32 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .st-p-x-32 {
    padding-right: 32px;
    padding-left: 32px;
  }

  .st-p-t-32 {
    padding-top: 32px;
  }

  .st-p-r-32 {
    padding-right: 32px;
  }

  .st-p-b-32 {
    padding-bottom: 32px;
  }

  .st-p-l-32 {
    padding-left: 32px;
  }

  .st-p-48 {
    padding: 48px;
  }

  .st-p-y-48 {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .st-p-x-48 {
    padding-right: 48px;
    padding-left: 48px;
  }

  .st-p-t-48 {
    padding-top: 48px;
  }

  .st-p-r-48 {
    padding-right: 48px;
  }

  .st-p-b-48 {
    padding-bottom: 48px;
  }

  .st-p-l-48 {
    padding-left: 48px;
  }

  .st-p-64 {
    padding: 64px;
  }

  .st-p-y-64 {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .st-p-x-64 {
    padding-right: 64px;
    padding-left: 64px;
  }

  .st-p-t-64 {
    padding-top: 64px;
  }

  .st-p-r-64 {
    padding-right: 64px;
  }

  .st-p-b-64 {
    padding-bottom: 64px;
  }

  .st-p-l-64 {
    padding-left: 64px;
  }

  .st-p-128 {
    padding: 128px;
  }

  .st-p-y-128 {
    padding-top: 128px;
    padding-bottom: 128px;
  }

  .st-p-x-128 {
    padding-right: 128px;
    padding-left: 128px;
  }

  .st-p-t-128 {
    padding-top: 128px;
  }

  .st-p-r-128 {
    padding-right: 128px;
  }

  .st-p-b-128 {
    padding-bottom: 128px;
  }

  .st-p-l-128 {
    padding-left: 128px;
  }

  .st-m-0 {
    margin: 0px;
  }

  .st-m-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .st-m-x-0 {
    margin-right: 0px;
    margin-left: 0px;
  }

  .st-m-t-0 {
    margin-top: 0px;
  }

  .st-m-r-0 {
    margin-right: 0px;
  }

  .st-m-b-0 {
    margin-bottom: 0px;
  }

  .st-m-l-0 {
    margin-left: 0px;
  }

  .st-m-4 {
    margin: 4px;
  }

  .st-m-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .st-m-x-4 {
    margin-right: 4px;
    margin-left: 4px;
  }

  .st-m-t-4 {
    margin-top: 4px;
  }

  .st-m-r-4 {
    margin-right: 4px;
  }

  .st-m-b-4 {
    margin-bottom: 4px;
  }

  .st-m-l-4 {
    margin-left: 4px;
  }

  .st-m-8 {
    margin: 8px;
  }

  .st-m-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .st-m-x-8 {
    margin-right: 8px;
    margin-left: 8px;
  }

  .st-m-t-8 {
    margin-top: 8px;
  }

  .st-m-r-8 {
    margin-right: 8px;
  }

  .st-m-b-8 {
    margin-bottom: 8px;
  }

  .st-m-l-8 {
    margin-left: 8px;
  }

  .st-m-12 {
    margin: 12px;
  }

  .st-m-y-12 {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .st-m-x-12 {
    margin-right: 12px;
    margin-left: 12px;
  }

  .st-m-t-12 {
    margin-top: 12px;
  }

  .st-m-r-12 {
    margin-right: 12px;
  }

  .st-m-b-12 {
    margin-bottom: 12px;
  }

  .st-m-l-12 {
    margin-left: 12px;
  }

  .st-m-16 {
    margin: 16px;
  }

  .st-m-y-16 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .st-m-x-16 {
    margin-right: 16px;
    margin-left: 16px;
  }

  .st-m-t-16 {
    margin-top: 16px;
  }

  .st-m-r-16 {
    margin-right: 16px;
  }

  .st-m-b-16 {
    margin-bottom: 16px;
  }

  .st-m-l-16 {
    margin-left: 16px;
  }

  .st-m-24 {
    margin: 24px;
  }

  .st-m-y-24 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .st-m-x-24 {
    margin-right: 24px;
    margin-left: 24px;
  }

  .st-m-t-24 {
    margin-top: 24px;
  }

  .st-m-r-24 {
    margin-right: 24px;
  }

  .st-m-b-24 {
    margin-bottom: 24px;
  }

  .st-m-l-24 {
    margin-left: 24px;
  }

  .st-m-32 {
    margin: 32px;
  }

  .st-m-y-32 {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .st-m-x-32 {
    margin-right: 32px;
    margin-left: 32px;
  }

  .st-m-t-32 {
    margin-top: 32px;
  }

  .st-m-r-32 {
    margin-right: 32px;
  }

  .st-m-b-32 {
    margin-bottom: 32px;
  }

  .st-m-l-32 {
    margin-left: 32px;
  }

  .st-m-48 {
    margin: 48px;
  }

  .st-m-y-48 {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .st-m-x-48 {
    margin-right: 48px;
    margin-left: 48px;
  }

  .st-m-t-48 {
    margin-top: 48px;
  }

  .st-m-r-48 {
    margin-right: 48px;
  }

  .st-m-b-48 {
    margin-bottom: 48px;
  }

  .st-m-l-48 {
    margin-left: 48px;
  }

  .st-m-64 {
    margin: 64px;
  }

  .st-m-y-64 {
    margin-top: 64px;
    margin-bottom: 64px;
  }

  .st-m-x-64 {
    margin-right: 64px;
    margin-left: 64px;
  }

  .st-m-t-64 {
    margin-top: 64px;
  }

  .st-m-r-64 {
    margin-right: 64px;
  }

  .st-m-b-64 {
    margin-bottom: 64px;
  }

  .st-m-l-64 {
    margin-left: 64px;
  }

  .st-m-128 {
    margin: 128px;
  }

  .st-m-y-128 {
    margin-top: 128px;
    margin-bottom: 128px;
  }

  .st-m-x-128 {
    margin-right: 128px;
    margin-left: 128px;
  }

  .st-m-t-128 {
    margin-top: 128px;
  }

  .st-m-r-128 {
    margin-right: 128px;
  }

  .st-m-b-128 {
    margin-bottom: 128px;
  }

  .st-m-l-128 {
    margin-left: 128px;
  }

  .breadcrumbs-dropdown {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .feature-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .feature-grid-2 {
    grid-row-gap: 48px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .feature-grid-3 {
    grid-row-gap: 48px;
    -ms-grid-columns: 1.5fr;
    grid-template-columns: 1.5fr;
  }

  .hero-grid {
    grid-column-gap: 48px;
    grid-row-gap: 48px;
    -ms-grid-columns: 1.25fr;
    grid-template-columns: 1.25fr;
    text-align: center;
  }

  .hero-grid-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    text-align: center;
  }

  .hero-title-centered {
    width: 100%;
  }

  .ctas-wrapper {
    margin-top: 1rem;
  }

  .faq-item-wrapper.box {
    margin-bottom: 16px;
  }

  .faq-item-wrapper.box.last {
    margin-bottom: 16px;
  }

  .faq-question {
    line-height: 1.5;
  }

  .pricing-card-1 {
    border-radius: 4px;
  }

  .pricing-2 {
    width: 1024px;
  }

  .pricing-3 {
    width: 1024px;
  }

  .testimonials-logos-grid {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .testimonial-box {
    padding: 32px;
  }

  .testimonial-box-2 {
    padding-right: 48px;
    padding-left: 48px;
  }

  .alert-banner {
    line-height: 1.5;
  }

  .feature-section-list {
    text-align: left;
  }

  .contact-grid {
    padding: 48px;
    grid-column-gap: 16px;
    grid-row-gap: 32px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .contact-image {
    width: 100%;
    height: 320px;
  }

  .pricing-table-wrapper {
    overflow: scroll;
  }

  .hero-grid-3 {
    grid-column-gap: 48px;
    grid-row-gap: 48px;
    -ms-grid-columns: 1.25fr;
    grid-template-columns: 1.25fr;
    text-align: center;
  }

  .hero-form-wrapper {
    margin-right: 0px;
    margin-left: 0px;
    padding: 32px;
    text-align: left;
  }

  .long-content-menu-left {
    -ms-grid-columns: 1.5fr;
    grid-template-columns: 1.5fr;
  }

  .nav-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .customize-page-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .customize-instructions {
    overflow: visible;
    width: 100%;
    height: auto;
    padding-right: 16px;
    padding-left: 16px;
  }

  .customize-preview {
    overflow: visible;
    width: 100%;
    height: auto;
  }

  .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .column {
    width: 100%;
  }

  .section-box-wrapper {
    padding: 24px;
  }

  .feature-content-wrapper.right {
    padding-left: 0px;
  }

  .feature-content-wrapper.left {
    padding-right: 0px;
  }

  .dropdown-menu-1.w--open {
    position: absolute;
    margin-top: 4px;
  }

  .dropdown-menu-item---navbar {
    padding-top: 12px;
    padding-bottom: 12px;
    color: #4d6d79;
    text-align: center;
  }

  .dropdown-menu-item---navbar:hover {
    background-color: #b3c0c6;
  }

  .dropdown-menu-item---navbar:active {
    color: #1a4353;
  }

  .dropdown-menu-item---navbar:focus {
    background-color: #b3c0c6;
  }

  .navbar-buttons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 8px;
    margin-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .navbar-buttons.mobile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .footer-navigation {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 24px;
    margin-bottom: 24px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .dropdown-menu-breadcrumbs.w--open {
    margin-top: 0px;
    background-color: #ccd5d9;
    text-align: center;
  }

  .tabs-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .tabs-menu-vertical {
    width: 100%;
    margin-right: 0px;
  }

  .tabs-content-vertical {
    width: 100%;
    margin-bottom: 32px;
    margin-left: 0px;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .section-title-column {
    width: 100%;
  }

  .slider-left-arrow {
    left: -24px;
  }

  .slider-right-arrow {
    right: -24px;
  }

  .dropdown-link-w-icon {
    text-align: left;
  }

  .left-arrow {
    display: none;
  }

  .right-arrow {
    display: none;
  }

  .megamenu-grid {
    padding-top: 8px;
    padding-bottom: 8px;
    grid-row-gap: 24px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    text-align: center;
  }

  .nav-megamenu-trigger {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .megamenu-dropdown {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .megamenu-mobile {
    display: block;
    border-bottom-style: none;
    border-radius: 6px;
    background-color: #ccd5d9;
  }

  .megamenu-mobile.dark {
    background-color: #002e40;
  }

  .dropdown-list {
    position: relative;
  }

  .dropdown-list.w--open {
    background-color: transparent;
  }

  .megamenu-nav-link-box {
    padding: 16px;
    font-size: 0.875rem;
    line-height: 1.5;
    text-align: left;
  }

  .megamenu-grid-2 {
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 8px;
    padding-bottom: 8px;
    grid-row-gap: 8px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    border-radius: 6px;
    text-align: center;
  }

  .megamenu-navlink-text-wrapper {
    margin-left: 16px;
    padding: 0px;
  }

  .preview-area {
    height: 640px;
  }

  .testimonial-box-small {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .grid-blog-1 {
    grid-row-gap: 32px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .card-blog-text-wrapper {
    padding-right: 0px;
    padding-left: 0px;
  }

  .card-blog-big-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .feature-grid-4 {
    grid-row-gap: 48px;
    -ms-grid-columns: 1.75fr;
    grid-template-columns: 1.75fr;
  }

  .feature-grid-4.reversed {
    grid-row-gap: 48px;
    -ms-grid-columns: 3fr;
    grid-template-columns: 3fr;
  }

  .l-g-2x1 {
    -ms-grid-columns: 2fr;
    grid-template-columns: 2fr;
  }

  .l-g-1x2 {
    -ms-grid-columns: 2fr;
    grid-template-columns: 2fr;
  }

  .footer-address {
    margin-top: 24px;
  }

  .text-wrapper-l {
    padding-right: 0px;
  }

  .hero-left-1-form-text {
    text-align: left;
  }

  .image-full-width {
    height: auto;
  }

  .hero-image-big-text-wrapper {
    text-align: center;
  }

  .image-responsive {
    height: auto;
  }

  .pricing-grid-1 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .pricing-card-2 {
    border-radius: 4px;
  }

  .pricing-card-4 {
    border-radius: 4px;
  }

  .testimonials-feature-text-wrapper {
    padding-right: 0px;
    text-align: center;
  }

  .long-content-menu-right {
    -ms-grid-columns: 1.5fr;
    grid-template-columns: 1.5fr;
  }

  .overview-list-grid {
    margin-top: 0px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    text-align: left;
  }

  .footer-bottom-docs {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }

  .footer-docs-navigation {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .testimonial-logo {
    width: 70%;
  }

  .megamenu-item-icon {
    background-color: #00a5e1;
    color: #e6eaec;
  }

  .megamenu-link-description {
    margin-bottom: 0rem;
  }

  .megamenu-link-description.dark {
    color: #66828c;
  }

  .megamenu-link-title {
    margin-bottom: 0rem;
  }
}

@media screen and (max-width: 767px) {
  h3 {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  h4 {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  h5 {
    margin-top: 12px;
    margin-bottom: 4px;
  }

  .container {
    padding-right: 16px;
    padding-left: 16px;
  }

  .container.container-navbar {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .container.container-box-section {
    max-width: none;
    padding-right: 0px;
    padding-left: 0px;
  }

  .heading.xl {
    margin-bottom: 12px;
  }

  .section-title {
    width: 100%;
  }

  .section-title.centered {
    width: 100%;
  }

  .l-g-3-col {
    grid-row-gap: 32px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .l-g-3-col.lt-g-2-col {
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .l-g-4-col {
    grid-column-gap: 16px;
    grid-row-gap: 32px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .l-g-4-col.features {
    grid-row-gap: 32px;
  }

  .button.small.cookies {
    margin-top: 16px;
    margin-left: 0px;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  .breadcrumbs-link.desktop {
    display: none;
  }

  .breadcrumbs-separator {
    display: none;
  }

  .l-g-2-col {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .l-g-2-col.cards {
    grid-row-gap: 32px;
  }

  .l-g-2-col.faq {
    grid-row-gap: 0px;
  }

  .cta-wrapper {
    width: 100%;
  }

  .footer {
    padding: 48px 0px 0px;
  }

  .icon-list-item.vertical {
    margin-bottom: 0.25rem;
    padding-right: 0px;
    padding-left: 0px;
  }

  .footer-grid {
    justify-items: center;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .footer-logo {
    padding-left: 0px;
  }

  .footer-bottom {
    margin-top: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .social-media-icon {
    font-size: 1.75rem;
    line-height: 1.75rem;
  }

  .sm-icon-link {
    margin-right: 12px;
  }

  .footer-grid-2 {
    -ms-grid-columns: 1.5fr;
    grid-template-columns: 1.5fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .footer-logo-2 {
    padding-left: 0px;
  }

  .footer-grid-3 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .footer-top-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer-2 {
    padding: 32px 0px;
  }

  .cta-section-form-wrapper {
    width: 360px;
    margin-right: auto;
    margin-left: auto;
  }

  .cta-section-form-wrapper.center {
    width: 100%;
  }

  .footer-menu-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .breadcrumbs.hero-breadcrumbs {
    margin-bottom: 48px;
  }

  .component-wrapper {
    margin-top: 56px;
    margin-bottom: 48px;
    padding: 16px;
  }

  .component-wrapper-label {
    top: -36px;
  }

  .style-guide-page-header {
    padding: 24px 0px 0px;
  }

  .grid-styleguide---buttons {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .navbar-vertical-content-wrapper {
    width: 85%;
  }

  .navbar-vertical-bg {
    width: 25%;
  }

  .nav-tab {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .sh-p-0 {
    padding: 0px;
  }

  .sh-p-y-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .sh-p-x-0 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .sh-p-t-0 {
    padding-top: 0px;
  }

  .sh-p-r-0 {
    padding-right: 0px;
  }

  .sh-p-b-0 {
    padding-bottom: 0px;
  }

  .sh-p-l-0 {
    padding-left: 0px;
  }

  .sh-p-4 {
    padding: 4px;
  }

  .sh-p-y-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .sh-p-x-4 {
    padding-right: 4px;
    padding-left: 4px;
  }

  .sh-p-t-4 {
    padding-top: 4px;
  }

  .sh-p-r-4 {
    padding-right: 4px;
  }

  .sh-p-b-4 {
    padding-bottom: 4px;
  }

  .sh-p-l-4 {
    padding-left: 4px;
  }

  .sh-p-8 {
    padding: 8px;
  }

  .sh-p-y-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .sh-p-x-8 {
    padding-right: 8px;
    padding-left: 8px;
  }

  .sh-p-t-8 {
    padding-top: 8px;
  }

  .sh-p-r-8 {
    padding-right: 8px;
  }

  .sh-p-b-8 {
    padding-bottom: 8px;
  }

  .sh-p-l-8 {
    padding-left: 8px;
  }

  .sh-p-12 {
    padding: 12px;
  }

  .sh-p-y-12 {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .sh-p-x-12 {
    padding-right: 12px;
    padding-left: 12px;
  }

  .sh-p-t-12 {
    padding-top: 12px;
  }

  .sh-p-r-12 {
    padding-right: 12px;
  }

  .sh-p-b-12 {
    padding-bottom: 12px;
  }

  .sh-p-l-12 {
    padding-left: 12px;
  }

  .sh-p-16 {
    padding: 16px;
  }

  .sh-p-y-16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .sh-p-x-16 {
    padding-right: 16px;
    padding-left: 16px;
  }

  .sh-p-t-16 {
    padding-top: 16px;
  }

  .sh-p-r-16 {
    padding-right: 16px;
  }

  .sh-p-b-16 {
    padding-bottom: 16px;
  }

  .sh-p-l-16 {
    padding-left: 16px;
  }

  .sh-p-24 {
    padding: 24px;
  }

  .sh-p-y-24 {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .sh-p-x-24 {
    padding-right: 24px;
    padding-left: 24px;
  }

  .sh-p-t-24 {
    padding-top: 24px;
  }

  .sh-p-r-24 {
    padding-right: 24px;
  }

  .sh-p-l-24 {
    padding-left: 24px;
  }

  .sh-p-b-24 {
    padding-bottom: 24px;
  }

  .sh-p-32 {
    padding: 32px;
  }

  .sh-p-y-32 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .sh-p-x-32 {
    padding-right: 32px;
    padding-left: 32px;
  }

  .sh-p-t-32 {
    padding-top: 32px;
  }

  .sh-p-r-32 {
    padding-right: 32px;
  }

  .sh-p-b-32 {
    padding-bottom: 32px;
  }

  .sh-p-l-32 {
    padding-left: 32px;
  }

  .sh-p-48 {
    padding: 48px;
  }

  .sh-p-y-48 {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .sh-p-x-48 {
    padding-right: 48px;
    padding-left: 48px;
  }

  .sh-p-t-48 {
    padding-top: 48px;
  }

  .sh-p-r-48 {
    padding-right: 48px;
  }

  .sh-p-b-48 {
    padding-bottom: 48px;
  }

  .sh-p-l-48 {
    padding-left: 48px;
  }

  .sh-p-64 {
    padding: 64px;
  }

  .sh-p-y-64 {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .sh-p-x-64 {
    padding-right: 64px;
    padding-left: 64px;
  }

  .sh-p-t-64 {
    padding-top: 64px;
  }

  .sh-p-r-64 {
    padding-right: 64px;
  }

  .sh-p-l-64 {
    padding-left: 64px;
  }

  .sh-p-b-64 {
    padding-bottom: 64px;
  }

  .sh-p-128 {
    padding: 128px;
  }

  .sh-p-y-128 {
    padding-top: 128px;
    padding-bottom: 128px;
  }

  .sh-p-x-128 {
    padding-right: 128px;
    padding-left: 128px;
  }

  .sh-p-t-128 {
    padding-top: 128px;
  }

  .sh-p-r-128 {
    padding-right: 128px;
  }

  .sh-p-b-128 {
    padding-bottom: 128px;
  }

  .sh-p-l-128 {
    padding-left: 128px;
  }

  .sh-m-0 {
    margin: 0px;
  }

  .sh-m-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .sh-m-x-0 {
    margin-right: 0px;
    margin-left: 0px;
  }

  .sh-m-t-0 {
    margin-top: 0px;
  }

  .sh-m-r-0 {
    margin-right: 0px;
  }

  .sh-m-b-0 {
    margin-bottom: 0px;
  }

  .sh-m-l-0 {
    margin-left: 0px;
  }

  .sh-m-4 {
    margin: 4px;
  }

  .sh-m-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .sh-m-x-4 {
    margin-right: 4px;
    margin-left: 4px;
  }

  .sh-m-t-4 {
    margin-top: 4px;
  }

  .sh-m-r-4 {
    margin-right: 4px;
  }

  .sh-m-b-4 {
    margin-bottom: 4px;
  }

  .sh-m-l-4 {
    margin-left: 4px;
  }

  .sh-m-8 {
    margin: 8px;
  }

  .sh-m-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .sh-m-x-8 {
    margin-right: 8px;
    margin-left: 8px;
  }

  .sh-m-t-8 {
    margin-top: 8px;
  }

  .sh-m-r-8 {
    margin-right: 8px;
  }

  .sh-m-b-8 {
    margin-bottom: 8px;
  }

  .sh-m-l-8 {
    margin-left: 8px;
  }

  .sh-m-12 {
    margin: 12px;
  }

  .sh-m-y-12 {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .sh-m-x-12 {
    margin-right: 12px;
    margin-left: 12px;
  }

  .sh-m-t-12 {
    margin-top: 12px;
  }

  .sh-m-r-12 {
    margin-right: 12px;
  }

  .sh-m-b-12 {
    margin-bottom: 12px;
  }

  .sh-m-l-12 {
    margin-left: 12px;
  }

  .sh-m-16 {
    margin: 16px;
  }

  .sh-m-y-16 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .sh-m-x-16 {
    margin-right: 16px;
    margin-left: 16px;
  }

  .sh-m-t-16 {
    margin-top: 16px;
  }

  .sh-m-r-16 {
    margin-right: 16px;
  }

  .sh-m-b-16 {
    margin-bottom: 16px;
  }

  .sh-m-l-16 {
    margin-left: 16px;
  }

  .sh-m-24 {
    margin: 24px;
  }

  .sh-m-y-24 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .sh-m-x-24 {
    margin-right: 24px;
    margin-left: 24px;
  }

  .sh-m-t-24 {
    margin-top: 24px;
  }

  .sh-m-r-24 {
    margin-right: 24px;
  }

  .sh-m-l-24 {
    margin-left: 24px;
  }

  .sh-m-b-24 {
    margin-bottom: 24px;
  }

  .sh-m-32 {
    margin: 32px;
  }

  .sh-m-y-32 {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .sh-m-x-32 {
    margin-right: 32px;
    margin-left: 32px;
  }

  .sh-m-t-32 {
    margin-top: 32px;
  }

  .sh-m-r-32 {
    margin-right: 32px;
  }

  .sh-m-b-32 {
    margin-bottom: 32px;
  }

  .sh-m-l-32 {
    margin-left: 32px;
  }

  .sh-m-48 {
    margin: 48px;
  }

  .sh-m-y-48 {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .sh-m-x-48 {
    margin-right: 48px;
    margin-left: 48px;
  }

  .sh-m-t-48 {
    margin-top: 48px;
  }

  .sh-m-r-48 {
    margin-right: 48px;
  }

  .sh-m-b-48 {
    margin-bottom: 48px;
  }

  .sh-m-l-48 {
    margin-left: 48px;
  }

  .sh-m-64 {
    margin: 64px;
  }

  .sh-m-y-64 {
    margin-top: 64px;
    margin-bottom: 64px;
  }

  .sh-m-x-64 {
    margin-right: 64px;
    margin-left: 64px;
  }

  .sh-m-t-64 {
    margin-top: 64px;
  }

  .sh-m-r-64 {
    margin-right: 64px;
  }

  .sh-m-b-64 {
    margin-bottom: 64px;
  }

  .sh-m-l-64 {
    margin-left: 64px;
  }

  .sh-m-128 {
    margin: 128px;
  }

  .sh-m-y-128 {
    margin-top: 128px;
    margin-bottom: 128px;
  }

  .sh-m-x-128 {
    margin-right: 128px;
    margin-left: 128px;
  }

  .sh-m-t-128 {
    margin-top: 128px;
  }

  .sh-m-r-128 {
    margin-right: 128px;
  }

  .sh-m-b-128 {
    margin-bottom: 128px;
  }

  .sh-m-l-128 {
    margin-left: 128px;
  }

  .breadcrumbs-dropdown {
    display: none;
  }

  .feature-grid {
    text-align: left;
  }

  .feature-grid-2 {
    text-align: left;
  }

  .faq-item-wrapper {
    margin-bottom: 16px;
  }

  .faq-item-wrapper.box {
    margin-bottom: 16px;
  }

  .faq-item-wrapper-2 {
    padding-top: 16px;
    padding-bottom: 16px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .faq-col-1 {
    width: 100%;
  }

  .faq-col-2 {
    width: 100%;
  }

  .pricing-2 {
    width: 1024px;
  }

  .pricing-3 {
    width: 800px;
  }

  .testimonials-logos-grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .testimonial-box-2 {
    padding: 48px 32px;
  }

  .alert-banner {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 0.875rem;
  }

  .contact-grid {
    padding: 32px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .pricing-table-wrapper {
    overflow: scroll;
  }

  .hero-grid-3 {
    grid-row-gap: 24px;
  }

  .hero-form-wrapper {
    margin-right: 0px;
    margin-left: 0px;
    padding: 32px;
  }

  .nav-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .alert-banner-2 {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 0.875rem;
  }

  .style-wrapper {
    margin-top: 48px;
    margin-bottom: 48px;
    padding: 16px;
  }

  .component-wrapper-2 {
    margin-top: 48px;
    margin-bottom: 48px;
    padding: 16px;
  }

  .component-wrapper-label-2 {
    top: -32px;
    font-size: 14px;
    line-height: 16px;
  }

  .hero-section.box {
    background-color: #ccd5d9;
  }

  .section-box-wrapper {
    padding: 24px;
  }

  .section-box-wrapper.c-b-neutral-10 {
    border-radius: 0px;
  }

  .footer-navigation {
    margin-top: 24px;
  }

  .cookie-notification {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    text-align: center;
  }

  .countdown-banner {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 0.875rem;
  }

  .list-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .testimonials-mask {
    max-width: 80%;
  }

  .grid-blog-1 {
    grid-row-gap: 32px;
  }

  .feature-grid-4 {
    grid-row-gap: 24px;
  }

  .wrapper-box {
    padding-right: 0px;
    padding-left: 0px;
  }

  .breadcrumbs-back-mobile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .breadcrumbs-dropdown-wrapper {
    display: none;
  }

  .feature-list-items {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .overview-list-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .footer-bottom-docs {
    margin-top: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer-docs-navigation {
    margin-top: 24px;
  }

  .example-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media screen and (max-width: 479px) {
  .section-title._2-columns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .feature-block.flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .l-g-4-col {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
  }

  .button.medium {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  .button.medium.form-button {
    margin-top: 4px;
  }

  .l-g-2-col {
    grid-row-gap: 24px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .input.medium {
    margin-bottom: 0.5rem;
  }

  .input.medium.horizontal {
    margin-right: 0px;
    margin-bottom: 8px;
  }

  .form.form-horizontal {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .feature-text-wrapper {
    padding-left: 0px;
  }

  .tab {
    display: block;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .footer {
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    text-align: left;
  }

  .material-icons.icon-left.icon-collapse {
    margin-top: 4px;
  }

  .nav-link {
    padding-right: 10px;
    padding-left: 10px;
  }

  .footer-grid {
    justify-items: center;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
    text-align: center;
  }

  .footer-logo {
    padding-left: 0px;
  }

  .footer-top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .footer-social-media {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .footer-bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }

  .vertical-menu-link {
    text-decoration: none;
  }

  .footer-grid-2 {
    -ms-grid-columns: 1.5fr;
    grid-template-columns: 1.5fr;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
    text-align: center;
  }

  .footer-logo-2 {
    margin-right: auto;
    margin-left: auto;
    padding-left: 0px;
  }

  .footer-grid-3 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
    text-align: center;
  }

  .footer-top-2 {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .footer-bottom-2 {
    margin-top: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }

  .footer-bottom-2.color-inverse {
    color: #4d6d79;
  }

  .footer-2 {
    padding-right: 0px;
    padding-left: 0px;
    text-align: left;
  }

  .sm-icons-wrapper {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .grid-cta {
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .cta-section-form-wrapper {
    width: 100%;
  }

  .footer-menu-wrapper {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .menu-button.w--open {
    background-color: #e6f6fc;
    color: #00a5e1;
  }

  .menu-button.color-inverse.w--open {
    color: #e6eaec;
  }

  .radio-button {
    margin-top: 0px;
  }

  .radio-button-field {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .switch-field {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .collapse-trigger {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .collapse-title {
    line-height: 1.5;
  }

  .collapse.borderless {
    padding: 16px;
  }

  .grid-styleguide---buttons {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
  }

  .navbar-vertical {
    text-align: left;
  }

  .sm-p-0 {
    padding: 0px;
  }

  .sm-p-y-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .sm-p-x-0 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .sm-p-t-0 {
    padding-top: 0px;
  }

  .sm-p-r-0 {
    padding-right: 0px;
  }

  .sm-p-b-0 {
    padding-bottom: 0px;
  }

  .sm-p-l-0 {
    padding-left: 0px;
  }

  .sm-p-4 {
    padding: 4px;
  }

  .sm-p-8 {
    padding: 8px;
  }

  .sm-p-12 {
    padding: 12px;
  }

  .sm-p-16 {
    padding: 16px;
  }

  .sm-p-24 {
    padding: 24px;
  }

  .sm-p-32 {
    padding: 32px;
  }

  .sm-p-48 {
    padding: 48px;
  }

  .sm-p-64 {
    padding: 64px;
  }

  .sm-p-128 {
    padding: 129px;
  }

  .sm-m-0 {
    margin: 0px;
  }

  .sm-m-8 {
    margin: 8px;
  }

  .sm-m-12 {
    margin: 12px;
  }

  .sm-m-16 {
    margin: 16px;
  }

  .sm-m-24 {
    margin: 24px;
  }

  .sm-m-32 {
    margin: 32px;
  }

  .sm-m-48 {
    margin: 48px;
  }

  .sm-m-64 {
    margin: 64px;
  }

  .sm-m-128 {
    margin: 128px;
  }

  .sm-p-y-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .sm-p-y-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .sm-p-y-12 {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .sm-p-y-16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .sm-p-y-24 {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .sm-p-y-32 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .sm-p-y-48 {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .sm-p-y-64 {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .sm-p-y-128 {
    padding-top: 128px;
    padding-bottom: 128px;
  }

  .sm-m-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .sm-m-x-0 {
    margin-right: 0px;
    margin-left: 0px;
  }

  .sm-m-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .sm-m-4 {
    margin: 4px;
  }

  .sm-m-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .sm-m-y-12 {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .sm-m-y-16 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .sm-m-y-24 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .sm-m-y-32 {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .sm-m-y-48 {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .sm-m-y-64 {
    margin-top: 64px;
    margin-bottom: 64px;
  }

  .sm-m-y-128 {
    margin-top: 128px;
    margin-bottom: 128px;
  }

  .sm-m-x-4 {
    margin-right: 4px;
    margin-left: 4px;
  }

  .sm-m-x-8 {
    margin-right: 8px;
    margin-left: 8px;
  }

  .sm-m-x-12 {
    margin-right: 12px;
    margin-left: 12px;
  }

  .sm-m-x-16 {
    margin-right: 16px;
    margin-left: 16px;
  }

  .sm-m-x-24 {
    margin-right: 24px;
    margin-left: 24px;
  }

  .sm-m-x-32 {
    margin-right: 32px;
    margin-left: 32px;
  }

  .sm-m-x-48 {
    margin-right: 48px;
    margin-left: 48px;
  }

  .sm-m-x-64 {
    margin-right: 64px;
    margin-left: 64px;
  }

  .sm-m-x-128 {
    margin-right: 128px;
    margin-left: 128px;
  }

  .sm-m-t-0 {
    margin-top: 0px;
  }

  .sm-m-t-4 {
    margin-top: 4px;
  }

  .sm-m-t-8 {
    margin-top: 8px;
  }

  .sm-m-t-12 {
    margin-top: 12px;
  }

  .sm-m-t-16 {
    margin-top: 16px;
  }

  .sm-m-t-24 {
    margin-top: 24px;
  }

  .sm-m-t-32 {
    margin-top: 32px;
  }

  .sm-m-t-48 {
    margin-top: 48px;
  }

  .sm-m-t-64 {
    margin-top: 64px;
  }

  .sm-m-t-128 {
    margin-top: 128px;
  }

  .sm-m-r-0 {
    margin-right: 0px;
  }

  .sm-m-r-4 {
    margin-right: 4px;
  }

  .sm-m-r-8 {
    margin-right: 8px;
  }

  .sm-m-r-12 {
    margin-right: 12px;
  }

  .sm-m-r-16 {
    margin-right: 16px;
  }

  .sm-m-r-24 {
    margin-right: 24px;
  }

  .sm-m-r-32 {
    margin-right: 32px;
  }

  .sm-m-r-48 {
    margin-right: 48px;
  }

  .sm-m-r-64 {
    margin-right: 64px;
  }

  .sm-m-r-128 {
    margin-right: 128px;
  }

  .sm-m-b-0 {
    margin-bottom: 0px;
  }

  .sm-m-b-4 {
    margin-bottom: 4px;
  }

  .sm-m-b-8 {
    margin-bottom: 8px;
  }

  .sm-m-b-12 {
    margin-bottom: 12px;
  }

  .sm-m-b-16 {
    margin-bottom: 16px;
  }

  .sm-m-b-24 {
    margin-bottom: 24px;
  }

  .sm-m-b-32 {
    margin-bottom: 32px;
  }

  .sm-m-b-48 {
    margin-bottom: 48px;
  }

  .sm-m-b-64 {
    margin-bottom: 64px;
  }

  .sm-m-b-128 {
    margin-bottom: 128px;
  }

  .sm-m-l-0 {
    margin-left: 0px;
  }

  .sm-m-l-4 {
    margin-left: 4px;
  }

  .sm-m-l-8 {
    margin-left: 8px;
  }

  .sm-m-l-12 {
    margin-left: 12px;
  }

  .sm-m-l-16 {
    margin-left: 16px;
  }

  .sm-m-l-24 {
    margin-left: 24px;
  }

  .sm-m-l-32 {
    margin-left: 32px;
  }

  .sm-m-l-48 {
    margin-left: 48px;
  }

  .sm-m-l-64 {
    margin-left: 64px;
  }

  .sm-m-l-128 {
    margin-left: 128px;
  }

  .sm-p-x-4 {
    padding-right: 4px;
    padding-left: 4px;
  }

  .sm-p-x-8 {
    padding-right: 8px;
    padding-left: 8px;
  }

  .sm-p-x-12 {
    padding-right: 12px;
    padding-left: 12px;
  }

  .sm-p-x-16 {
    padding-right: 16px;
    padding-left: 16px;
  }

  .sm-p-x-24 {
    padding-right: 24px;
    padding-left: 24px;
  }

  .sm-p-x-32 {
    padding-right: 32px;
    padding-left: 32px;
  }

  .sm-p-x-48 {
    padding-right: 48px;
    padding-left: 48px;
  }

  .sm-p-x-64 {
    padding-right: 64px;
    padding-left: 64px;
  }

  .sm-p-x-128 {
    padding-right: 128px;
    padding-left: 128px;
  }

  .sm-p-t-4 {
    padding-top: 4px;
  }

  .sm-p-t-8 {
    padding-top: 8px;
  }

  .sm-p-t-12 {
    padding-top: 12px;
  }

  .sm-p-t-16 {
    padding-top: 16px;
  }

  .sm-p-t-24 {
    padding-top: 24px;
  }

  .sm-p-t-32 {
    padding-top: 32px;
  }

  .sm-p-t-48 {
    padding-top: 48px;
  }

  .sm-p-t-64 {
    padding-top: 64px;
  }

  .sm-p-t-128 {
    padding-top: 128px;
  }

  .sm-p-r-4 {
    padding-right: 4px;
  }

  .sm-p-r-8 {
    padding-right: 8px;
  }

  .sm-p-r-12 {
    padding-right: 12px;
  }

  .sm-p-r-16 {
    padding-right: 16px;
  }

  .sm-p-r-24 {
    padding-right: 24px;
  }

  .sm-p-r-32 {
    padding-right: 32px;
  }

  .sm-p-r-48 {
    padding-right: 48px;
  }

  .sm-p-r-64 {
    padding-right: 64px;
  }

  .sm-p-r-128 {
    padding-right: 128px;
  }

  .sm-p-b-4 {
    padding-bottom: 4px;
  }

  .sm-p-b-8 {
    padding-bottom: 8px;
  }

  .sm-p-b-12 {
    padding-bottom: 12px;
  }

  .sm-p-b-16 {
    padding-bottom: 16px;
  }

  .sm-p-b-24 {
    padding-bottom: 24px;
  }

  .sm-p-b-32 {
    padding-bottom: 32px;
  }

  .sm-p-b-48 {
    padding-bottom: 48px;
  }

  .sm-p-b-64 {
    padding-bottom: 64px;
  }

  .sm-p-b-128 {
    padding-bottom: 128px;
  }

  .sm-p-l-4 {
    padding-left: 4px;
  }

  .sm-p-l-8 {
    padding-left: 8px;
  }

  .sm-p-l-12 {
    padding-left: 12px;
  }

  .sm-p-l-16 {
    padding-left: 16px;
  }

  .sm-p-l-24 {
    padding-left: 24px;
  }

  .sm-p-l-32 {
    padding-left: 32px;
  }

  .sm-p-l-48 {
    padding-left: 48px;
  }

  .sm-p-l-64 {
    padding-left: 64px;
  }

  .sm-p-l-128 {
    padding-left: 128px;
  }

  .feature-grid-2 {
    grid-row-gap: 48px;
  }

  .ctas-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 1rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .testimonials-logos-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .testimonial-box-2 {
    padding: 32px 16px;
  }

  .alert-banner {
    padding-bottom: 4px;
  }

  .contact-grid {
    padding: 24px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .hero-form-wrapper {
    padding: 24px;
  }

  .alert-banner-2 {
    padding-bottom: 4px;
    text-align: left;
  }

  .brand-logo-image {
    height: 1.25rem;
  }

  .tabs-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .section-box-wrapper {
    padding: 24px;
  }

  .avatar.large {
    width: 48px;
    height: 48px;
  }

  .avatar-wrapper.horizontal {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .footer-navigation {
    margin-top: 24px;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  .section-title-column {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }

  .tabs-switch-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .tab-switch {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
  }

  .testimonials-slide {
    width: 320px;
  }

  .megamenu-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .megamenu-nav-link-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .megamenu-grid-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .megamenu-navlink-text-wrapper {
    margin-left: 0px;
  }

  .preview-area {
    height: 800px;
  }

  .testimonial-box-small {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .cta-box {
    padding: 48px 24px;
  }

  .pricing-card-3 {
    padding: 32px;
  }

  .button-content-wrapper {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .feature-list-items {
    grid-row-gap: 8px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .overview-list-grid {
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .footer-bottom-docs {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }

  .footer-docs-navigation {
    margin-top: 24px;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  .testimonial-logo {
    width: 50%;
  }

  .megamenu-item-icon {
    margin-bottom: 1rem;
  }
}

#w-node-_3ceec1c7-a853-a728-f0a3-11110cf65490-7c82be8f {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_227aa4c4-1cc8-d34a-f605-25e2fac2b0b7-7c82be8f {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-c74be733-34bb-8d6c-b685-cbcbcc8d7cb1-cc8d7ca6 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 6;
  grid-column-start: span 6;
  -ms-grid-column-span: 6;
  grid-column-end: span 6;
}

#w-node-c74be733-34bb-8d6c-b685-cbcbcc8d7cbc-cc8d7ca6 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-c74be733-34bb-8d6c-b685-cbcbcc8d7cc6-cc8d7ca6 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-c74be733-34bb-8d6c-b685-cbcbcc8d7cd0-cc8d7ca6 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-b3010a2d-59f4-e8e7-9395-4625131945d8-131945cd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 6;
  grid-column-start: span 6;
  -ms-grid-column-span: 6;
  grid-column-end: span 6;
}

#w-node-b3010a2d-59f4-e8e7-9395-4625131945e3-131945cd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-b3010a2d-59f4-e8e7-9395-4625131945ed-131945cd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_4d6aea45-216f-a305-5441-eb15370ba49a-be82be95 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-cbe93518-008c-c958-6cac-0456a499d6ff-1a20dcd8 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_31cb8a00-da38-4f1e-a2e9-554aa6d3b530-a6d3b518 {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-d1a9e73a-4bc3-3b42-2960-6ae9a8e3cedf-a8e3cea2 {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-bdac670b-446b-7bd3-1602-660901061cee-01061ce5 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_54e8e409-857f-b3ac-67b0-2e65cced7566-cced755b {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_102114bc-6c20-81d4-5ea6-6a099426a045-9426a03c {
  -ms-grid-column-align: end;
  justify-self: end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_6d12a1bb-ff6c-7104-10eb-63793543d292-3543d289 {
  -ms-grid-column-align: end;
  justify-self: end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_12043139-7844-c569-f011-516ccb0704c1-cb0704b6 {
  -ms-grid-column-align: end;
  justify-self: end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-f4ef8fcd-f29f-5151-502d-7de2712d72df-712d72d6 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-dedf54a3-9ff1-5427-4012-ace2d0602351-d0602348 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_7927aa13-4373-63e1-11ff-744df68f286a-f68f285f {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_1e8d7594-c33e-d7a6-b7ce-65ae9ffbd6a0-9ffbd698 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-eafbc070-20cb-8493-0600-2a57c2ac0763-c2ac075b {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_98d1977c-8ad1-bed3-7f90-6c8f6d874fd5-6d874fcb {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_3ab56a4f-983b-c645-23c1-9f15293b3a9c-bc9177bd {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_8ca58d2a-d600-6121-b59f-70cee829647f-e829644c {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-fbba13b3-03db-72fc-e9da-5476548ac42c-548ac3f9 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_47e9cb1a-7ca8-6727-31f2-073d8235f96e-2382bed2 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f814b7f8-6606-8e4f-4131-f8872ceb6460-2382bed2 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-f814b7f8-6606-8e4f-4131-f8872ceb646a-2382bed2 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-f814b7f8-6606-8e4f-4131-f8872ceb6474-2382bed2 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_0329da1b-37ac-8abe-8508-4b903443d828-2382bed2 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_658ddd13-617e-7d63-aed4-31208db3dced-2382bed2 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_28259b75-5edd-83ab-3d62-29e5cae11078-2382bed2 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_37e02779-61ab-b5c8-3800-aad80deb749b-2382bed2 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_37e02779-61ab-b5c8-3800-aad80deb74a5-2382bed2 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_37e02779-61ab-b5c8-3800-aad80deb74af-2382bed2 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_37e02779-61ab-b5c8-3800-aad80deb74b9-2382bed2 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_37e02779-61ab-b5c8-3800-aad80deb74c3-2382bed2 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_37e02779-61ab-b5c8-3800-aad80deb74cd-2382bed2 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_3a5957ce-51a0-68eb-b36e-45cc4a95e5b4-5282bed9 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_27e1573b-7faa-5c82-cc35-0cef86f4bec7-5282bed9 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_05b145b4-ffa1-b9ae-7986-b0bd832bae0a-5282bed9 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_4e5b2621-2259-9f4c-9499-a8b160c89c33-60c89bfd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_4e5b2621-2259-9f4c-9499-a8b160c89c50-60c89bfd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_4e5b2621-2259-9f4c-9499-a8b160c89c64-60c89bfd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_4e5b2621-2259-9f4c-9499-a8b160c89c81-60c89bfd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_3c38fd4d-e23e-9ae5-89f4-f427f93bf5f5-f93bf5a9 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_6621b908-446f-705b-0eb8-1ffbfb841b19-5182bee0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_087c6529-5e1f-0c92-1918-ca42ba7fd3b9-aa82beee {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6621b908-446f-705b-0eb8-1ffbfb841b19-4dea7947 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_8d6fd009-4281-c618-8c01-4710a45f3157-1fe8aa8b {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

@media screen and (max-width: 991px) {
  #w-node-_986dd887-2a81-65c3-0063-79f923da43a9-23da439a {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-_986dd887-2a81-65c3-0063-79f923da43b2-23da439a {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-_986dd887-2a81-65c3-0063-79f923da43c1-23da439a {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-_986dd887-2a81-65c3-0063-79f923da43f9-23da439a {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-c74be733-34bb-8d6c-b685-cbcbcc8d7cb1-cc8d7ca6 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-c74be733-34bb-8d6c-b685-cbcbcc8d7cb3-cc8d7ca6 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-c74be733-34bb-8d6c-b685-cbcbcc8d7cb4-cc8d7ca6 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-c74be733-34bb-8d6c-b685-cbcbcc8d7cbc-cc8d7ca6 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-c74be733-34bb-8d6c-b685-cbcbcc8d7cc6-cc8d7ca6 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-c74be733-34bb-8d6c-b685-cbcbcc8d7cd0-cc8d7ca6 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-b3010a2d-59f4-e8e7-9395-4625131945d8-131945cd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-b3010a2d-59f4-e8e7-9395-4625131945da-131945cd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-b3010a2d-59f4-e8e7-9395-4625131945db-131945cd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-b3010a2d-59f4-e8e7-9395-4625131945e3-131945cd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-b3010a2d-59f4-e8e7-9395-4625131945ed-131945cd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-bdac670b-446b-7bd3-1602-660901061cee-01061ce5 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_54e8e409-857f-b3ac-67b0-2e65cced7566-cced755b {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_102114bc-6c20-81d4-5ea6-6a099426a045-9426a03c {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_6d12a1bb-ff6c-7104-10eb-63793543d292-3543d289 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_12043139-7844-c569-f011-516ccb0704c1-cb0704b6 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-f4ef8fcd-f29f-5151-502d-7de2712d72df-712d72d6 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-dedf54a3-9ff1-5427-4012-ace2d0602351-d0602348 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_7927aa13-4373-63e1-11ff-744df68f286a-f68f285f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_1e8d7594-c33e-d7a6-b7ce-65ae9ffbd6a0-9ffbd698 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-eafbc070-20cb-8493-0600-2a57c2ac0763-c2ac075b {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_98d1977c-8ad1-bed3-7f90-6c8f6d874fd5-6d874fcb {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_2ae81888-6ba8-ca67-402c-b24d8e0e2501-e0238619 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_3fa84d9c-f288-188b-8601-60c2f3246ce2-e0238619 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_57433b79-c4bf-7899-545a-53924f5aacfe-e0238619 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_806bec91-5997-332c-7aa5-c3a9e6390bdf-863c55ff {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-_652559d1-79b3-6e57-cf44-28920eccdd56-d9dfd6ce {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_4d3c3188-0038-7fab-9796-778bd7a32883-7c0dd497 {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-_557a42d5-d2ec-3773-c2eb-462e891e1926-29fe9bf5 {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-_2e3e6954-d16b-9de2-a0f0-2c3179fdaf8c-93bade7b {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-_9f8e46c9-e6b0-92aa-90f7-1226a586cd8a-7896003a {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-_97dea878-81c4-3c0b-7423-38db70986641-15f8bf65 {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-_8b5e2516-86f9-f53a-c8d0-67653d46f696-1d7ed89c {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-_3425d505-7be6-b48a-a404-f91f9e530550-925ba67b {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-_0f019b5d-e324-23ac-9db8-f4e1c6d1897a-86661a0e {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-_527340d5-8eb7-38cc-decb-9907b3e7552d-34d50c4f {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-b4610269-af1b-a435-1771-a79c6dcee084-69c82631 {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-_6715ac67-07f2-a6e7-7ff7-853bce39fd7f-ce39fd7b {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-_44029b2c-dffa-6086-35aa-073c48ebe486-48ebe461 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_7dde023c-7314-1e39-d24c-fa471acc5762-1acc575f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_8ca58d2a-d600-6121-b59f-70cee829647f-e829644c {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_55448bd9-6fa8-584f-063e-8786b18850bd-b18850ba {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-e6deb8f1-2f42-5c77-21ab-674dd4fad89f-d4fad87a {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-e8c5ac3b-5140-0ca0-f500-a629bdf96978-bdf96975 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-fbba13b3-03db-72fc-e9da-5476548ac42c-548ac3f9 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_6377f0eb-4e7a-4c95-b23b-e9c8c55c265f-c55c265c {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-e47497e5-cbea-22c9-2cff-56d526b7de7f-7482becc {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-_2ddcadf6-bdbb-8337-dfc9-c0bc3e9ecbd2-2382bed2 {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-_16ae7aaf-ad41-486a-8761-73b40df6400b-0df64007 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-bac5f9ed-d695-8af0-59ee-19f60c630bed-0c630be9 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_434a8897-1202-d4cc-cece-cfae070118f5-070118e1 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-bbb51a92-5fc7-5b2b-d2d1-03c454e3161e-54e3161e {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-_76bde3f0-af0e-355d-a329-b4df283b6d85-7ed7a6ce {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_76bde3f0-af0e-355d-a329-b4df283b6dbc-7ed7a6ce {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_76bde3f0-af0e-355d-a329-b4df283b6df3-7ed7a6ce {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-e5b4e691-251d-76b9-92ca-67367219a120-7ed7a6ce {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-e5b4e691-251d-76b9-92ca-67367219a157-7ed7a6ce {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-e5b4e691-251d-76b9-92ca-67367219a18e-7ed7a6ce {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_247c1ed1-4d5b-ccb3-ec07-4df923a8b93b-23a8b930 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_247c1ed1-4d5b-ccb3-ec07-4df923a8b965-23a8b930 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_247c1ed1-4d5b-ccb3-ec07-4df923a8b994-23a8b930 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-fa67f50d-baa2-d6ff-868f-edc88ee41eb0-a05eeb6b {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-e49bd22c-ee0d-69d2-30c6-dc989f2e9426-a05eeb6b {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-bec59c72-8b71-921e-faf5-53df8faf9666-a05eeb6b {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_26339d4e-7cff-a5cb-7a82-81f6e70d316f-5182bee0 {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-_4b18efe3-bd62-d17f-3a96-7236be4fc7a7-aa82beee {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-_26339d4e-7cff-a5cb-7a82-81f6e70d316f-4dea7947 {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-_3f7eb29c-025a-43c2-a4c7-35ed61d0d87e-73e4f603 {
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }
}

@media screen and (max-width: 767px) {
  #w-node-_8ca58d2a-d600-6121-b59f-70cee829647f-e829644c {
    -ms-grid-column-align: auto;
    justify-self: auto;
  }

  #w-node-fbba13b3-03db-72fc-e9da-5476548ac42c-548ac3f9 {
    -ms-grid-column-align: auto;
    justify-self: auto;
  }
}

@media screen and (max-width: 479px) {
  #w-node-_102114bc-6c20-81d4-5ea6-6a099426a045-9426a03c {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }

  #w-node-_6d12a1bb-ff6c-7104-10eb-63793543d292-3543d289 {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }

  #w-node-_12043139-7844-c569-f011-516ccb0704c1-cb0704b6 {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }

  #w-node-_44029b2c-dffa-6086-35aa-073c48ebe464-48ebe461 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_55448bd9-6fa8-584f-063e-8786b18850bd-b18850ba {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }

  #w-node-e6deb8f1-2f42-5c77-21ab-674dd4fad87d-d4fad87a {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_6377f0eb-4e7a-4c95-b23b-e9c8c55c265f-c55c265c {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}